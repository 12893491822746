export default {
  ru: {
    course: 'Программы',
    school_course: 'Программы',
    my_course: 'Мои программы',
    schedule: 'Расписание',
    user: 'Пользователи',
    analytics: 'Аналитика',
    catalog: 'Каталог',
    onboard: 'Обучение',
    info: 'Инфо',
    admin_school: 'Админ',
    manager: 'Менеджер',
    teacher: 'Учитель',
    student: 'Студент',
    create_course: 'Создать программу',
    filter: 'Фильтр',
    choose_category: 'Выберите категорию',
    choose_group: 'Выберите группу',
    select_city: 'Выберите город',
    choose_price: 'Выберите стоимость',
    select_level: 'Выберите уровень',
    select_univ: 'Выберите университет!',
    select_certif: 'Выберите сертификат',
    search_by_text: 'Поиск по тексту',
    count_of_stud: 'Кол-во студентов',
    count_of_lessons: 'Кол-во уроков',
    students: 'студента',
    lessons: 'урока',
    course_name_kz: 'Название программы на казахском',
    course_name_ru: 'Название программы на русском',
    course_name_en: 'Название программы на английском',
    lecturer_short: 'Препод.',
    lecturer: 'Преподаватели',
    lecture: 'Преподаватель',
    the_students: 'Студенты',
    the_studentss: 'Студенты ',
    lesson: 'Уроки',
    categories: 'Категории',
    category: 'Категория',
    actions: 'Действия',
    delete: 'Удалить',
    edit: 'Редактировать',
    view: 'Посмотреть',
    view_more: 'Посмотреть подробнее',
    my_profile: 'Мой профиль',
    exit: 'Выйти',
    back: 'Назад',
    creating_course: 'Создание программы',
    add_category: 'Добавить категорию',
    add: 'Добавить',
    del_category: 'Удалить категорию',
    del_edit_category: 'Редактировать/Удалить категорию',
    add_degree: 'Добавить степень',
    del_degree: 'Удалить степень',
    del_edit_degree: 'Редактировать/Удалить степень',
    toast_del_category_success: 'Категория успешно удалена!',
    toast_edit_category_success: 'Категория успешно обновлена!',
    toast_hw_sent_success: 'Ответы задания успешно отправлены!',
    main_info: 'Основная информация',
    choose_lang_course: 'Выберите язык программы',
    instruction_add_course: 'Видео-инструкция по созданию программы',
    course_not: 'Не создано ни одной программы.',
    try_add_course: 'Самое время их создать!',
    price_of_course: 'Стоимость курса',
    submission_fee: 'Стоимость подачи',
    submission_fee_tenge: 'Стоимость подачи (тенге)',
    open_rounds: 'Подача открыта',
    price_of_study: 'Стоимость обучения',
    price_of_study_currency: 'Стоимость обучения (в валюте)',
    free_course: 'Бесплатная программа',
    category_of_course: 'Категория курса',
    display_the_course: 'Отображать программу в каталоге',
    send_notif_course: 'Отправлять рассылки',
    add_unverified: 'Добавить для сортировки "Непроверенные"',
    select_teacher: 'Выберите учителей',
    close: 'Закрыть',
    grant: 'Грант',
    search_by_teacher: 'Поиск по учителям...',
    search_by_student: 'Поиск по студентам...',
    selected_teachers: 'Выбранные учителя',
    selected_students: 'Выбранные студенты',
    details_of_course: 'Детали программы',
    short_course_desc_ru: 'Краткое описание программы на русском',
    short_course_desc_kz: 'Краткое описание программы на казахском',
    short_course_desc_en: 'Краткое описание программы на английском',
    full_course_desc_ru: 'Полное описание программы на русском',
    full_course_desc_kz: 'Полное описание программы на казахском',
    full_course_desc_en: 'Полное описание программы на английском',
    show_catalog_course: 'Появится в каталоге курсов',
    course_cover: 'Обложка программы',
    this_img_will_be_show_on_course_cover: 'Это изображение будет отображаться в каталоге программ',
    path_to_file: 'Укажите путь к файлу или перенесите его в эту область.',
    not_exceed: 'Файл не должен превышать размера 30 МБ',
    not_exceed_20: 'Файл не должен превышать размера 20 МБ',
    select_file: 'Выбрать файл',
    media_on_page: 'Медиа на странице программы (необязательно)',
    it_can_be_photo: 'Это может быть фото, видео, демо-урок или можете прикрепить ссылку из Youtube',
    course_created_success: 'Программа успешно создана!',
    course_round_created_success: 'Раунды сохранены успешно!',
    course_poster_saved_success: 'Медиа сохранены успешно!',
    course_rounds_limit: 'Количество раундов ограничено!',
    category_created_success: 'Категория успешно создана!',
    toast_degree_created_success: 'Степень успешно создана!',
    toast_degree_deleted_success: 'Степень успешно удалена!',
    toast_degree_upded_success: 'Степень успешно обновлен!',
    course_created_error: 'Ошибка! Проверьте данные!',
    auth: 'Авторизация',
    auth_mail: 'Ваша эл. почта',
    auth_password: 'Ваш пароль',
    auth_forget_pass: 'Забыли пароль?',
    recall_password: 'Вспомнил(-а) пароль',
    auth_enter: 'Войти',
    lang_course: 'Язык обучения',
    title_course: 'Поиск по тексту',
    cost_course: 'Стоимость',
    non_select: 'Не выбран',
    recovery_password: 'Восстановление пароля',
    recovery: 'Восстановить',
    mail_on: 'Письмо у вас на почте!',
    confirm_reset_password: 'Сгенерированный пароль отправлен на вашу почту!',
    go_back: 'Вернуться назад',
    fill_fields: 'Заполните это поле!',
    select_all: 'Выбрать все',
    remove_all: 'Убрать все',
    category_name: 'Название категории',
    degree_name: 'Степень',
    about_course: 'О программе',
    lesson_list: 'Список уроков',
    course_analytic: 'Аналитика курса',
    edit_course: 'Редактировать программу',
    edit_programs: 'Редактировать программы',
    delete_course: 'Удалить курс',
    delete_lesson: 'Удалить урок',
    delete_group: 'Удалить группу',
    categories_of_course: 'Категории курса',
    features_of_course: 'Преимущества',
    sure_remove_entry: 'Вы уверены, что хотите удалить эту запись?',
    sure_remove_work_schedule: 'Вы уверены, что хотите убрать этот график работы',
    sure_remove_lesson_schedule: 'Вы уверены, что хотите убрать этот график учебы',
    sure_remove_course: 'Вы уверены, что хотите убрать эту программу',
    sure_delete_course: 'Вы уверены, что хотите удалить эту программу',
    sure_delete_selected_course: 'Вы уверены, что хотите удалить выбранные программы',
    sure_delete_lesson: 'Вы уверены, что хотите удалить эту программу',
    sure_delete_user: 'Вы уверены, что хотите удалить пользователя из базы',
    agree_del: 'Да, удалить',
    agree_remove: 'Да, убрать',
    cancel: 'Отменить',
    all_lessons: 'Все уроки',
    all_sections: 'Все разделы',
    create_lessons: 'Создать раздел',
    vido_lesson: 'Видео урок',
    test_lesson: 'Тест',
    live_lesson: 'Live урок',
    duration: 'Длительность',
    minute: 'мин',
    preview: 'Предпросмотр',
    no_lesson: 'Нет ни одного урока.',
    add_teacher: 'Добавить преподавателя',
    add_student: 'Добавить студента',
    groups: 'Группы',
    courses_teacher: 'Преподаватели программы',
    courses_student: 'Студенты программы',
    fullname: 'Имя, Фамилия',
    mail: 'Эл. почта',
    tgram: 'Телеграм',
    phone: 'Телефон',
    birth: 'День рождения',
    courses_group: 'Группы программы',
    add_group: 'Создать группу',
    name_group: 'Название группы',
    edit_group: 'Редактировать группу',
    saturday: 'Суббота',
    monday: 'Понедельник',
    tuesday: 'Вторник',
    wednesday: 'Среда',
    thursday: 'Четверг',
    friday: 'Пятница',
    sunday: 'Воскресенье',
    select_instructor: 'Выберите преподавателя',
    save: 'Сохранить',
    seacrh_teacher: 'Поиск по преподавателям',
    select_student: 'Выберите студента',
    search_student: 'Поиск по студентам',
    delete_teacher: 'Убрать преподавателя',
    delete_student: 'Убрать студента',
    sure_remove_teacher_from_course: 'Вы уверены, что хотите убрать преподавателя с программы',
    sure_remove_student_from_group: 'Вы уверены, что хотите убрать студента из группы',
    sure_delete_group: 'Вы уверены, что хотите удалить группу',
    create_group: 'Создание группы',
    instruction_add_group: 'Видео-инструкция по созданию группы',
    group_name: 'Название группы',
    group_teacher: 'Преподаватель группы',
    select_students: 'Выберите студентов',
    group_type: 'Тип группы',
    trial_lesson: 'Пробный урок',
    trial_lessons: 'Пробные уроки',
    indiv_lesson: 'Индивидуальный урок',
    group_lesson: 'Групповые уроки',
    pair_lesson: 'Парные уроки',
    groups_schedule: 'Расписание группы',
    no_schedule: 'Сейчас расписания нет',
    add_lesson: 'Добавить урок',
    select_lesson_format: 'Выберите формат урока',
    day_of_week: 'День недели',
    start_time: 'Время начала',
    end_time: 'Время окончания',
    all_user: 'Все пользователи',
    administration: 'Администрация',
    amount_of_course: 'Кол. прог.',
    amount_of_lesson: 'Количество уроков',
    last_login: 'Последний вход (дни)',
    add_user: 'Добавить пользователя',
    role: 'Роль',
    action: 'Действия',
    reset_filter: 'Сбросить фильтр',
    del_user: 'Удалить пользователя',
    video_instruction_create_user: 'Видео-инструкция по созданию пользователя',
    creating_user: 'Создание пользователя',
    select_role: 'Выберите роль',
    create_user: 'Создать пользователя',
    name: 'Имя',
    last_name: 'Фамилия',
    password: 'Пароль',
    generate: 'Генерировать',
    email_has_already: 'Почта уже существует!',
    change_photo: 'Изменить фото',
    add_photo: 'Добавить фото',
    teachers_courses: 'Курсы преподавателя',
    work_schedule: 'График работы',
    add_course: 'Добавить курс',
    add_graph: 'Добавить график',
    select_course: 'Выберите курс',
    search_by_course: 'Поиск по курсам',
    remove_course: 'Убрать курс',
    remove_lesson_schedule: 'Убрать график учебы',
    remove_work_schedule: 'Убрать график работы',
    add_work_schedule: 'Добавить график работы',
    edit_work_schedule: 'Редактировать график работы',
    add_lesson_schedule: 'Добавить график учебы',
    edit_lesson_schedule: 'Редактировать график учебы',
    choose_suit_schedule: 'Выберите подходящий график',
    start: 'Начало',
    end: 'Окончание',
    main: 'Основные',
    communications: 'Коммуникация',
    mailing: 'Рассылка',
    comment: 'Комментарии',
    documents: 'Документы',
    document: 'Документ',
    filter_by_date: 'Фильтр по дате:',
    from: 'от',
    to: 'до',
    your_comment: 'Ваш комментарий здесь',
    add_comments: 'Добавить комментарий',
    balance: 'Баланс студента',
    students_course: 'Курсы студента',
    payment: 'Оплата',
    lessonss: 'занятий',
    add_pay: 'Добавить оплату',
    show_p_history: 'Показать историю',
    study_schedule: 'График учебы',
    lesson_format: 'Формат урока',
    lesson_type: 'Тип урока',
    select_format_lesson: 'Выберите формат урока',
    select_type_lesson: 'Выберите тип урока',
    select_schedule: 'Выберите расписание',
    add_schedule: 'Добавить расписание',
    search_payment: 'Поиск платежа по тексту',
    payment_history: 'История оплаты',
    payment_date: 'Дата оплаты',
    recommend_teacher: 'Рекомендуемые учителя ',
    video_instruction_create_lesson: 'Видео-инструкция по созданию раздела',
    create_lesson: 'Создание раздела',
    info_about_lesson: 'Информация о разделе',
    constructor: 'Конструктор заданий',
    name_of_lesson: 'Название раздела',
    video_material: 'Видео-материал',
    select_video_display_lesson: 'Выберите видео, которое будет отображаться в разделе',
    lesson_section: 'Разделы',
    section_name: 'Название раздела',
    add_section: 'Добавить раздел',
    order: 'Порядок',
    create: 'Создать',
    task_test_type: 'Тестовое задание',
    task_media_test_type: 'Тестовое задание с изображением',
    task_media_type: 'Вставить медиа-файл',
    task_youtube_type: 'Вставить ссылку Youtube',
    task_word_enter_type: 'Вставить слова в текст',
    task_word_enter_select_type: 'Вставить слова с вариантами',
    task_table_enter_type: 'Вставить слово в таблице',
    task_table_enter_select_type: 'Вставить варианты в таблице',
    task_find_error_type: 'Найти ошибки в тексте',
    task_text_type: 'Текстовое задание',
    task_compare_type: 'Сопоставить слова',
    task_essay_type: 'Написать эссе',
    task_file_check_type: 'Проверка файла студента',
    task_file_add_type: 'Вставить файл к уроку',
    task_select_one_type: 'Выбрать один из вариантов',
    create_task_lesson: 'Создать задание к уроку',
    task_type: 'Тип задания',
    answer_options: 'Варианты ответов',
    note_correct_answer: 'Также отметьте правильные ответы',
    immediately_show_correct_answer: 'Сразу показывать правильный ответ студенту',
    option: 'Вариант',
    add_options: 'Добавить вариант',
    save_task: 'Сохранить задание',
    correct_answer: 'Это правильный вариант',
    answer_text: 'Текст вопроса',
    add_file: 'Добавить файл',
    would_be_ppt_media: 'Это может быть презентация или видео урок',
    recommend_file: 'Рекомендации к файлу',
    img_format: 'Форматы изображения',
    video_format: 'Форматы видео',
    video_audio_format: 'Форматы видео/аудио',
    audio_format: 'Форматы аудио',
    file_format: 'Форматы файла',
    if_media_more: 'Если файл превышает',
    mb: 'Мб',
    media_recom: 'Изображения меньшего размера загружаются и отображаются быстрее на дисплее компьютера',
    usefull_links: 'Полезные ссылки:',
    ok: 'Хорошо',
    img_compression: 'Сжатия изображения',
    video_compression: 'Сжатие видео',
    audio_compression: 'Сжатие аудио',
    step_1_2: 'Шаг 1/2. Текст задания',
    step_2_2: 'Шаг 2/2. Выделите слова, которые нужно будет вставить',
    save_text: 'Сохранить текст',
    back_to_text: 'Вернуться к тексту',
    text_your_text: 'Напишите сюда свой текст и после сможете добавить слова для пропусков',
    add_pair: 'Создайте пары',
    create_pair: 'Добавить пару',
    field_essay: 'У студента появится поле для ввода эссе',
    field_load: 'У студента появится поле для загрузки файла',
    pin: 'Закрепить',
    name_of_task: 'Название задания',
    pin_student_file: 'Можете прикрепить файлы для скачивания студентами',
    step_1_2_table: 'Шаг 1/2. Составьте и заполните таблицу',
    strings: 'Строки',
    columns: 'Столбцы',
    error_options: 'Вариант ошибки',
    add_task: 'Добавить задание',
    mulitmedia: 'Мультимедиа',
    fill_all_gaps: 'Вам нужно заполнить все пропуски, теми словами, которые вы считаете правильными',
    essay_text: 'Текст',
    your_note: 'Ваша заметка',
    delete_task: 'Удалить задание',
    sure_delete_task: 'Вы уверены, что хотите удалить это задание',
    word_1: 'Слово 1',
    word_2: 'Слово 2',
    cannot_change: 'Количество столбцов и строк менять нельзя!',
    not_found_query: 'К сожалению, по данному запросу ничего не найдено!',
    catalog_empty: 'На данный момент каталог пуст',
    user_empty: 'На данный момент список пользователей пуст',
    edit_lesson: 'Редактирование раздела',
    edit_lessons: 'Редактирование разделов',
    my_balance: 'Мой остаток',
    start_lesson: 'Начать урок',
    start_course: 'Начать курс',
    buy_course: 'Купить курс',
    open_access: 'Открыть доступ',
    course_of_lang: 'Язык курса',
    del_section: 'Удалить раздел',
    edit_section: 'Редактировать раздел',
    sure_delete_section: 'Вы уверены, что хотите удалить раздел',
    exercises_empty: 'На данный момент нет раздела для задач',
    end_lesson: 'Завершить урок',
    a_lesson: 'Урок',
    answers: 'Ответы',
    sure_finish_lesson: 'Вы уверены, что хотите завершить урок',
    agree_finish: 'Да, завершить',
    interactive_board: 'Интерактивная доска',
    back_to_tasks: 'Вернуться к заданиям',
    reset_answers: 'Сбросить ответы',
    check: 'Проверить',
    a_course: 'Курс',
    clear: 'Очистить',
    doesnt_support: 'Ваш браузер не поддерживает этот файл',
    students_answer: 'Ответы студентов',
    answer_no: 'Ответов от студентов нет',
    empty_payment_history: 'Нет истории оплаты',
    select_section: 'Выберите раздел',
    a_group: 'Группа',
    an_action: 'Действие',
    write: 'Написать',
    correct: 'правильно',
    the_tasks: 'заданий',
    show_answers: 'Показать ответы',
    hide_answers: 'Скрыть ответы',
    correct_answers: 'Правильные ответы',
    a_correct_answer: 'Правильный ответ',
    students_answers: 'Ответы студента',
    a_students_answer: 'Ответ студента',
    send_letter: 'Отправить письмо',
    send: 'Отправить',
    subject_mail: 'Тема письма',
    text_mail: 'Текст письма',
    date: 'дата',
    recommend: 'Рекомендуется',
    regular_lesson: 'Регулярные уроки',
    empty_data_list: 'На данный момент данных нет',
    empty_document_list: 'На данный момент документов нет',
    empty_notif_list: 'На данный момент рассылки нет',
    empty_comment_list: 'На данный момент комментариев нет',
    empty_schedule_list: 'На данный момент нет графика учебы',
    today: 'Сегодня',
    week: 'неделя',
    day: 'день',
    my_settings: 'Мои настройки',
    personal_settings: 'Личные настройки',
    school_settings: 'Настройки школы',
    newsletter: 'Рассылка',
    my_tariff: 'Мой тариф',
    country: 'Страна',
    school_name: 'Название школы',
    school_desc: 'Описание школы',
    school_adress: 'Адрес школы',
    school_currency: 'Валюта школы',
    school_allowed_cancel: 'Кол-во разрешенных отмен за месяц',
    school_allowed_cancel_for: 'Отмена разрешена за',
    school_allowed_transfer: 'Кол-во разрешенных переносов за месяц',
    school_allowed_transfer_for: 'Перенос разрешен за',
    work_time: 'Рабочие часы',
    auto_lesson: 'Автоматически проводить вчерашние уроки',
    minute_f: 'минут',
    hour: 'час',
    a_hour: 'часа',
    hi_test: 'Привет, Тест!',
    notif_lesson: 'Напоминаем, что у вас урок по курсу {название курса} через в {lesson.time_from} с учителем {имя студента}',
    wish_lesson: 'Желаем провести урок с пользой',
    if_cant_notif: 'Если у вас не получается, в личном кабинете сможете перенести или отменить урок',
    with_scincerly: '– С уважением, команда “Название школы”!',
    notification_lesson: 'Напоминание об уроке',
    new_mailing: 'Новая рассылка',
    create_mailing: 'Создать рассылку',
    recipients: 'Получатели',
    over_period_time: 'За период времени',
    all: 'Все',
    text_mailing: 'Текст рассылки',
    trial: 'Пробный',
    indiv: 'Индивидуальный',
    group: 'Групповой',
    pair: 'Парный',
    change: 'Изменить',
    add_mailing: 'Добавить рассылку',
    delete_notif: 'Удалить напоминание',
    sure_notif_delete: 'Вы уверены, что хотите удалить напоминание',
    update_tariff: 'Обновить тариф',
    payment_a_history: 'История платежей',
    active: 'Активен',
    no_active: 'Не активен',
    monthly_payment: 'Ежемесячный платеж',
    number_students: 'Количество студентов',
    next_payment: 'Следующий платеж',
    number_months: 'Количество месяцев',
    total: 'Итого:',
    pay: 'Оплатить',
    sum: 'Сумма',
    transactions: '№ транзакции',
    description: 'Описание',
    lesson_done: 'Урок проведен',
    reschedule_lesson: 'Перенести урок',
    cancel_lesson: 'Отменить урок',
    return: 'Вернуть',
    filter_by_schedule: 'Фильтр по графику',
    by_lesson: 'По урокам',
    by_students: 'По студентам',
    empty_analytic: 'На данный момент аналитика пуста.',
    empty_analytic_1: 'Начните развивать свою школу, чтобы аналитика появилась',
    graphs: 'Графики',
    report: 'Отчет',
    jan: 'ЯНВ',
    feb: 'ФЕВ',
    mar: 'МАР',
    apr: 'АПР',
    may: 'МАЙ',
    jun: 'ИЮН',
    jul: 'ИЮЛ',
    avg: 'АВГ',
    sen: 'СЕН',
    okt: 'ОКТ',
    nov: 'НОЯ',
    dec: 'ДЕК',
    january: 'январь',
    february: 'февраль',
    march: 'март',
    april: 'апрель',
    mays: 'май',
    june: 'июнь',
    july: 'июль',
    august: 'август',
    september: 'сентябрь',
    october: 'октябрь',
    november: 'ноябрь',
    december: 'декабрь',
    select_by_course: 'Выбор по программе',
    select_by_status: 'Выбор по статусу',
    amount_of_purchase: 'Количество покупок',
    sum_of_sales: 'Сумма продаж',
    search__student: 'Поиск студента',
    search__teacher: 'Поиск преподавателя',
    more: 'Подробнее',
    purchases: 'Покупки',
    selling: 'Продажа',
    done: 'Сделано',
    progress: 'Прогресс',
    current_month: 'Текущий месяц',
    conducted_lesson: 'Проведенные уроки',
    canceled_lesson: 'Отмененные уроки',
    transfered_lesson: 'Перенесенные уроки',
    statistic_type_lesson: 'Статистика по типу уроков',
    individual_lesson: 'Индивидуальные уроки',
    conducted: 'Проведено',
    canceled: 'Отменен',
    postponed: 'Перенесено',
    implementation: 'Реализация',
    select_by_student: 'Выбор по студенту',
    a_lessons: 'урок',
    attendance_of_lessons: 'Посещение уроков',
    effectiveness_of_lessons: 'Эффективность уроков',
    total_lessons: 'Всего уроков',
    exercises: 'Упражнения',
    a_correct: 'Правильно',
    incorrect: 'Неправильно',
    correct_done: 'Правильно / Сделано',
    all_right_reserved: 'Все права защищены',
    personal_data: 'Обработка персональных данных',
    confidentiality: 'Конфиденциальность',
    terms_of_use: 'Пользовательское соглашение',
    online_payments_security: 'Безопасность онлайн платежей',
    open: 'Открыть',
    for_questions: 'По вопросам',
    fill_category_name: 'Заполните название категории',
    fill_course_name: 'Заполните название курса',
    fill_category: 'Выберите категорию!',
    fill_teacher: 'Выберите преподавателя!',
    fill_short_desc: 'Заполните краткое описание курса!',
    fill_full_desc: 'Заполните полное описание курса!',
    fill_poster: 'Выберите обложку курса!',
    course_update_media_upload: 'Программа успешно обновлена! Подождите, медиа файл грузится!',
    courses_update: 'Программы успешно обновлены!',
    lessons_update: 'Уроки успешно обновлены!',
    media_uploaded: 'Медиа файл успешно загрузился!',
    round_updated: 'Дата успешно обновился!',
    round_deleted: 'Дата успешно было удалено!',
    fill_media: 'Выберите медиа файл!',
    fill_lesson_name: 'Заполните название урока!',
    lesson_created_success: 'Урок успешно создан!',
    fill_duration: 'Заполните длительность!',
    fill_order: 'Заполните порядок!',
    lesson_edited_success: 'Урок успешно обновлен!',
    fill_name: 'Заполните имя!',
    fill_last_name: 'Заполните фамилию!',
    fill_phone: 'Заполните телефон!',
    profile_data_update: 'Персональные данные пользователя обновлены!',
    avatar_apdate: 'Аватар обновлен!',
    fill_school_name: 'Заполните название школы!',
    fill_adress_name: 'Заполните адрес школы!',
    fill_allow_cancel: 'Укажите количество разрешенных отмен за месяц!',
    fill_allow_transfer: 'Укажите количество разрешенных переносов за месяц!',
    success_school_name: 'Данные школы обновлены!',
    fill_email: 'Заполните эл.почту!',
    fill_password: 'Заполните пароль!',
    user_created_success: 'Пользователь успешно создан!',
    user_updated_success: 'Пользователь успешно обновлен!',
    user_deleted_success: 'Пользователь успешно удален!',
    toast_work_schedule: 'График работы добавлен!',
    toast_work_schedule_edit: 'График работы обновлен!',
    fill_course: 'Выберите курс!',
    fill_price: 'Укажите стоимость!',
    fill_count_lesson: 'Укажите количество уроков!',
    toast_course_add: 'Курс добавлен!',
    toast_course_edit: 'Данные баланса пользователя обновлены!',
    toast_study_schedule: 'График учебы добавлен!',
    toast_study_schedule_edit: 'График учебы обновлен!',
    toast_format: 'Выберите формат урока!',
    toast_type: 'Выберите тип урока!',
    toast_group_name: 'Заполните название группы!',
    toast_students: 'Выберите студентов!',
    toast_group_create: 'Группа успешно создана!',
    toast_group_update: 'Группа успешно обнавлена!',
    toast_mail_send: 'Письмо успешно отправлено!',
    toast_mail_send_error: 'Ошибка! Проверьте адрес эл.почты пользователя!',
    toast_section_name: 'Заполните название раздела!',
    toast_section_order: 'Заполните порядок раздела!',
    toast_section_add: 'Раздел добавлен!',
    toast_section_name_edit: 'Данные о разделе изменены!',
    toast_section_delete: 'Раздел успешно удален!',
    toast_test_task: 'Заполните название задания!',
    toast_test_task_answer: 'Заполните текст вопроса!',
    toast_test_task_options: 'Заполните текст вариантов!',
    toast_test_task_options_add: 'Добавьте вариант!',
    toast_test_task_options_correct: 'Выберите правильный вариант!',
    toast_task_added: 'Задание успешно добавлено!',
    toast_task_table_r_c: 'Укажите количество столбцов и строк!',
    toast_test_task_text: 'Заполните текст задания!',
    toast_task_connect_add: 'Добавьте пару!',
    toast_task_connect_word: 'Заполните слова!',
    task_updated_success: 'Задание успешно обновлено!',
    something_went_wrong: 'Что-то пошло не так, попробуйте снова...',
    toast_notif_add: 'Рассылка успешно добавлена!',
    toast_notif_delete: 'Рассылка успешно удалена!',
    toast_comment_added: 'Комментарий успешно добавлен!',
    toast_comment_field: 'Заполните поле комментарий!',
    toast_avail_course_added: 'Курс успешно добавлен!',
    toast_avail_course_delete: 'Курс успешно удален!',
    toast_lesson_schedule_delete: 'График учебы успешно удалено!',
    toast_work_schedule_delete: 'График работы успешно удален!',
    toast_lesson_delete: 'Урок успешно удален!',
    toast_lesson_copy: 'Урок успешно скопирован!',
    toast_teacher_course_remove: 'Преподаватель успешно убран из списка!',
    toast_teacher_course_added: 'Преподаватель успешно добавлен в список!',
    toast_student_course_remove: 'Студент успешно убран из списка!',
    toast_student_course_added: 'Студент успешно добавлен в список!',
    empty_course: 'На данный момент нет курса.',
    my_group: 'Мои группы',
    my_groups_student: 'Студенты моей группы',
    search: 'Поиск',
    no_matches: 'Нет совпадений',
    no_utm: 'Нет отметок UTM',
    kazakh: 'Казахский',
    rus: 'Русский',
    eng: 'Английский',
    turk: 'Турецкий',
    nem: 'Немецкий',
    venger: 'Венгерский',
    copy: 'Копировать курс',
    copy_lesson: 'Копировать урок',
    toast_copy_success: 'Курс успешно скопирован!',
    toast_empty_program: 'У программы нет раздела!',
    select_degree: 'Выберите степень',
    select_format: 'Выберите формат',
    select_range_data: 'Выберите диапазон дат',
    offer: 'Оферта',
    i_have_acc: 'У меня есть аккаунт',
    i_have_no_acc: 'У меня нет аккаунта',
    account_create: 'Аккаунт создан',
    registrate: 'Регистрация',
    italy: 'Итальянский',
    greece: 'Греческий',
    chez: 'Чешский',
    terms: 'Сроки',
    del_format: 'Удалить формат',
    del_edit_format: 'Редактировать/Удалить формат',
    add_format: 'Добавить формат',
    fill_format: 'Заполните название формата',
    name_format: 'Название формата',
    course_format: 'Формат',
    toast_format_created_success: 'Формат успешно создан!',
    toast_format_deleted_success: 'Формат успешно удален!',
    toast_format_upded_success: 'Формат успешно обновлен!',
    format: 'Формат',
    univer: 'Университет',
    del_univer: 'Удалить университет',
    del_edit_univer: 'Редактировать/Удалить университет',
    add_univer: 'Добавить университет',
    fill_univer: 'Заполните название университета',
    name_univer: 'Название университета',
    toast_univer_created_success: 'Университет успешно создан!',
    toast_univer_deleted_success: 'Университет успешно удален!',
    toast_univer_upded_success: 'Университет успешно обновлен!',
    speciality: 'Факультет',
    del_special: 'Удалить факультет',
    del_edit_special: 'Редактировать/Удалить факультет',
    add_special: 'Добавить факультет',
    fill_special: 'Заполните название факультета',
    name_special: 'Название факультета',
    toast_special_created_success: 'Факультет успешно создан!',
    toast_special_deleted_success: 'Факультет успешно удален!',
    toast_special_upded_success: 'Факультет успешно обновлен!',
    del_city: 'Удалить город',
    del_edit_city: 'Редактировать/Удалить город',
    add_city: 'Добавить город',
    fill_city: 'Заполните название города',
    name_city: 'Название города',
    course_city: 'Город',
    toast_city_created_success: 'Город успешно создан!',
    toast_city_deleted_success: 'Город успешно удален!',
    toast_city_upded_success: 'Город успешно обновлен!',
    city: 'Город',
    fill_starte_date: 'Заполните сроки!',
    poster_url: 'Ссылка на видео в Youtube',
    link_youtube: 'Прикрепить ссылку видео из Youtube',
    deadlines_for_submission: 'Сроки подачи',
    poster_media: 'Выбрать свой медиафайл',
    all_exercise_should_be_done: 'Выполните все задания',
    not_task_with_grade_count: 'Нет задач, которые высчитывают правильные ответы',
    extended_filter: 'Расширенный фильтр',
    hide: 'Скрыть',
    how_it_works: 'Как работает Excourse?',
    text_onboard: 'Пройдите небольшое обучение и пользуйтесь платформой легко и просто!',
    later: 'Позже',
    get_trained: 'Пройти обучение',
    select_training_section: 'Выберите раздел обучения',
    display_course_catalog: 'В каталоге будут отображаться все доступные программы',
    educate: 'Образование',
    educate_certfifcate: 'Образование (сертификаты)',
    certificate: 'Сертификаты',
    utm_page: 'UTM метки',
    final_document: 'Окончательные документы',
    add_edu: 'Добавить образование',
    middle_school: 'Среднее образование (школа, колледж)',
    school: 'Школа',
    college: 'Колледж',
    bachelor: 'Бакалавриат',
    magistracy: 'Магистратура',
    phd: 'Докторантура',
    profession: 'Профессия',
    profession_name: 'Название профессии',
    period_of_study: 'Период обучения (год)',
    ends: 'Конец',
    avg_rate: 'Средняя оценка (оценки, GPA и другое)',
    form_of_rate: 'Форма оценки (GPA, балл или другое)',
    rate: 'Балл',
    fill_profession_name: 'Заполните название профессии!',
    fill_start_period: 'Заполните начало периода обучения!',
    fill_form_of_rate: 'Заполните форму оценки!',
    del_edu: 'Удалить образование',
    sure_del_edu: 'Вы уверены, что хотите удалить эту степень образования',
    toast_edu_add_success: 'Степень образования успешно добавлено!',
    toast_edu_edit_success: 'Степень образования успешно обновлено!',
    toast_edu_delete_success: 'Степень образования успешно удалено!',
    fill_certif_name: 'Заполните название сертификата!',
    fill_lang_level_name: 'Выберите язык!',
    fill_lang_level: 'Выберите уровень знания языка!',
    add_certif: 'Добавить сертификат',
    a_certificate: 'Сертификат',
    certif_name: 'Название (IELTS, TOEFL)',
    total_score: 'Общий балл',
    toast_certif_add_success: 'Сертификат успешно добавлен!',
    toast_certif_edit_success: 'Сертификат успешно обновлен!',
    toast_certif_delete_success: 'Сертификат успешно удален!',
    del_certif: 'Удалить сертификат',
    sure_del_certif: 'Вы уверены, что хотите удалить этот сертификат',
    if_check_all_task_done: 'Проверка выполнения всех задач в разделе',
    second_hint: 'Здесь находятся ваши личные настройки',
    first_hint: 'В панели мои программы вы можете увидеть купленные программы',
    third_hint: 'Здесь находятся ваше расписание',
    fourth_hint: 'Здесь появится аналитика по вашим программам',
    select_learn_select: 'Выберите раздел обучения',
    one_section: 'Какие разделы есть в Excourse?',
    onboard_button: 'Полностью ознакомился(-ась) с видео',
    three_section: 'Как и где заполнять, прикреплять данные/документы?',
    two_section: 'Где настроить мой профиль?',
    press_my_profile: 'Нажмите сюда и выберите “Мой профиль”',
    super: 'Супер!',
    onboard_done: 'Вы прошли обучение по платформе!',
    next: 'Далее',
    fill_edu_and_certif: 'Давайте теперь заполним данные об образовании и о сертификатах в разделах "Образование" и "Сертификаты"!',
    requisites: 'Реквизиты',
    thank_you: 'Благодарим за покупку!',
    numb_pay: 'Номер заказа',
    country_category: 'Страна/Категория',
    search_pr_city_univ: 'Поиск (специальность, город, вуз)',
    free: 'Бесплатно',
    starts: 'Начать',
    enter_a_code: 'Введите код',
    sms_first: 'На почту',
    sms_second: ' отправлен код. Введите его здесь:',
    sms_third: 'и на почту',
    catalog_of_scecial: 'Каталог специальностей',
    send_again: 'Отправить еще раз код',
    send_again_2: 'через',
    number_phone_8: 'Номер телефона должен начинаться с "+7"',
    number_input_full: 'Заполните номер телефона',
    send_code: 'Отправить код',
    faq: 'Часто задаваемые вопросы',
    faq_menu: 'FAQ',
    select_lesson: 'Выберите урок',
    select_es_list: 'Выберите страницу задач',
    empty_exercise_list: 'На данный момент задач нет',
    date_joined: 'Дата рег-ции',
    should_add_timer: 'Раздел с таймером',
    timer: 'Таймер',
    timer_stop: 'Время истекло!',
    start_exam: 'Начать экзамен',
    finish_exam: 'Завершить экзамен',
    u_r_going_to_start: 'Вы начинаете выполнение задач',
    result: 'Результат',
    time: 'Время',
    video_warn: 'Приносим свои извинения за временное отсутствие видеоурока! Все пользователи, без исключения, получат доступ к видеоуроку после как его загрузят!',
    notes: 'Примечание',
    checked: 'Проверено',
    all_checked: 'Проверены все',
    no_checked: 'Не проверены',
    tasks: 'Задачи',
    round_should_be_today_or: 'Примечание: Не допускается прошедшая дата в поле "до"!',
    add_date: 'Добавить дату',
    multi_media: 'Медиа файлы',
    from_comp: 'Загрузить медиафайл из компьютера',
    should_select_file: 'Просим выбрать файл!',
    selected_media: 'Выбрано файлов',
    count_of_massive_course: 'Достигло лимита по количеству!',
    count_of_massive_course_select_one: 'Выберите программы по одному!',
    count_of_massive_section_select_one: 'Выберите разделы по одному!',
    not_checked: 'Непроверенные',
    by_programms: 'По программам',
    by_education: 'По образованию',
    by_status: 'По статусу',
    edit_date: 'Фильтр по дате редактирования:',
    edit_date_table: 'Дата ред.',
    min_percent: 'Задать процент минимального порога',
    dont_pass: 'Вам нужно набрать проходной. Процент должен быть выше:',
    ielts: 'IELTS',
    toefl: 'TOEFL',
    fce: 'FCE',
    cae: 'CAE',
    cpe: 'CPE',
    bec: 'BEC',
    my_language_know: 'Уровень знания языка',
    my_language_know_add: 'Добавить иностранный язык',
    my_language_know_lang_input: 'Язык',
    my_language_know_lang_input_select: 'Выберите язык',
    my_language_know_lang_input_level: 'Уровень',
    my_language_added_success: 'Данные были успешно сохранены!',
    my_language_del_success: 'Данные были успешно  удалены!',
    list_of_edu: 'Данные об образований',
    next_btn: 'Дальше',
    teaching: 'Обучение',
    language_edu: 'Языковые знания',
    select_start_year: 'Выбрать год (начало)',
    select_end_year: 'Выбрать год (конец)',
    remove: 'Убрать',
    no_certif: 'Нет сертификата',
    from_year: 'С начала года',
    to_year: 'По конец года',
    else: 'Еще',
    planned: 'Запланировано',
    finished: 'Завершен',
    touched: 'Перенесен',
    cancelled: 'Отменен',
    else_lesson: 'Еще уроки',
    certif_req_add: 'Добавить требования по языковым сертификатам',
    lang_req_add: 'Добавить требования по знаниям языка',
    req_add: 'Добавить требования',
    req_s: 'Требования',
    form_of_property: 'Форма собственности',
    edit_del_property: 'Редактировать/Удалить форму собственности',
    add_property: 'Добавить форму собственности',
    name_property: 'Название формы собственности',
    toast_property_created_success: 'Форма собственности успешно создан!',
    toast_property_deleted_success: 'Форма собственности успешно удален!',
    toast_property_upded_success: 'Форма собственности успешно обновлен!',
    optional: 'необязательно',
    all_list: 'Все программы',
    discleim: 'Для того, чтобы наша система смогла подобрать для вас самые подходящие варианты, заполните обязательные данные. Система пропустит вас дальше, только после заполнения первичной информации',
    fill_all: 'Заполните все поля!',
    by_default: 'По умолчанию',
    start_expensive: 'Сначала дорогие',
    start_cheap: 'Сначала дешевые',
    sort_by_cost: 'По стоимости обучения',
    sort_by_price: 'По стоимости подачи',
    check_mentor: 'Надежный куратор',
    priority_consideration: 'Станьте приоритетным абитуриентом!',
    econom_benefit: 'Экономия и выгода',
    partnership_univ: 'Дополнительный бонус!',
    welcome_platform: 'Добро пожаловать на нашу платформу!',
    agragate_excourse_1: 'Мы не просто портал загрузки документов. Excourse - это казахстанский проект, агрегатор образовательных программ.',
    our_mission_1: 'Наша миссия - сделать процесс подачи документов легче, быстрее и дешевле.',
    if_u_have_question: 'Мы эксперты в этих вопросах!',
    what_kind_doc_u_need: 'Какие документы нужны для поступления?',
    is_motiv_letter_right: 'Правильно ли составлено мое мотивационное эссе?',
    how_to_find_error: 'Как выявить ошибки в документах?',
    proceed_to_paym: 'Перейти к оплате',
    mentor_feedback_first_1: 'Каждый документ пройдет проверку нашими менторами. Мы помогаем сделать все идеально, и постоянно',
    mentor_feedback_first_2: 'предоставляем обратную связь,',
    mentor_feedback_first_3: 'чтобы увеличить шансы на поступление.',
    mentor_feedback_first_4: 'Профессиональный взгляд',
    mentor_feedback_first_5: 'поможет избежать ошибок и собрать конкурентоспособный пакет документов.',
    priority_consider_1: 'При выборе ВУЗа-партнера, ваши документы будут рассмотрены в первых рядах. Ваша',
    priority_consider_2: 'заявка будет обработана быстрее,',
    priority_consider_3: 'а вероятность получения приглашения на учебу увеличивается многократно. Это не просто отправка документов – это',
    priority_consider_4: 'уверенность в результате.',
    econom_benefit_1: 'За 50 000 тенге вы получаете комплексную проверку документов от кураторов и гайдлайны для',
    econom_benefit_2: 'значительного повышения шанса на поступление.',
    econom_benefit_3: '',
    not_all_1: 'Excourse бонусом предоставляет',
    not_all_2: 'материалы для подготовки к IELTS.',
    not_all_3: 'Это дополнительное преимущество позволит вам повысить свои языковые навыки и подготовиться к тесту.',
    edu_you_have_already: 'Укажите ваше образование (на момент заполнения данной анкеты)',
    fill_school_p: 'Если вы еще в школе, пропишите "ШКОЛЬНИК". В остальных случаях, укажите специальность',
    fill_gpa: 'Вы можете выбрать одну из часто используемых форм: \n 5-бальная шкала (для школьников),\n 4-бальная шкала (для студентов) \n либо 100% шкала',
    fill_period: 'Укажите год начала и окончания обучения.',
    five_scale: '5-бальная шкала (для школьников, оценка)',
    four_scale: '4-бальная шкала (для студентов, GPA)',
    hundred_scale: '100% шкала',
    swipe_up: 'проведите пальцем вверх',
    select_programm: 'Выберите программу',
    not_free_teacher_time: 'Нет свободного учителя на заданное время',
    note_order_only_integers: 'Порядок принимает только целые числа',
    enter_name: 'Введите название',
    download_doc: 'Загрузить документ',
    smthg_went_wrong: 'Что-то пошло не так! Попробуйте загрузить еще раз!',
    doc_uploaded: 'Документ загрузился!',
    doc_del: 'Документ успешно удален!',
    pack: 'Пакет',
    country_edu: 'Страна поступления',
    for_mailing: 'Для рассылки',
    we_prepare_program: 'Просим подождать! Мы подбираем программу под вас',
    file_larger: 'Выберите файл, размер которого меньше 20 МБ!',
    stages: 'Этапы',
    academic_year: 'Учебный год',
    responsible: 'Ответственный',
    user_status: 'Статус',
    package_cost: 'Стоимость пакета',
    balance_of_payment: 'Остаток по оплате',
    ielts_preparation: 'Подготовка к IELTS',
    ielts_teacher: 'Учитель IELTS',
    ielts_available: 'IELTS есть',
    ielts_takes: 'IELTS сдает',
    visa: 'Виза',
    visa_support: 'Сопровождение по визе',
    applying_by_proxy: 'По доверке подаемся',
    registered_for_visa: 'Записали на визу',
    visa_date: 'Дата на визу',
    applied_for_visa: 'На визу подали',
    Visa_re_registration: 'Перезапись на визу',
    guardian: 'Опекун',
    visa_submission_city: 'Город подачи визы',
    visa_ready: 'Виза готов',
    legal_who_is_translating: 'Легал кто перевод',
    legal_translation_ready: 'Легал перевод готов',
    legal_sent: 'Легал отправлено',
    legal_ready: 'легал готов',
    tracking_number_Legal: 'трекинг номер Легал',
    DDV_sent: 'ДДВ отправлен',
    DDV_ready: 'ДДВ готов',
    Tracking_number_DDV: 'Трекинг номер ДДВ',
    Submitted_for_apostille: 'Сдали на апостиль',
    apostille_ready: 'апостиль готов',
    codice_confirm: 'Кодиче загрузить',
    codice_ready: 'Кодиче запросили',
    questionnaire_for_finish_passport: 'Анкетирование на финку',
    list_of_financial_documents_ready_sent: 'список фин готов/отправлен',
    financial_documents_ready: 'фин доки готовы',
    submitted_for_Finnish_passport_grant: 'Подали на финку (грант)',
    access: 'Доступы',
    departure_date: 'Дата вылета',
    residence_permit_submitted: 'ВНЖ подали',
    CAF_submission: 'подача КАФ',
    SIM_opened: 'SIM открыли',
    codice_fiscali_submitted: 'Codice fiscali подали',
    apartment_options: 'Варианты квартир',
    found_housing: 'Нашли жилье',
    date_task_type: 'Вставить дату',
    grouped_by: 'Группированные по',
    gender: 'Пол',
    pre_enrollment: 'Pre-enrollment загрузить',
    pre_enrollment_wait: 'Pre-enrollment запросили',
    pre_enrollment_have: 'Pre-enrollment есть',
    add_room: 'Добавить квартиры',
    add_access: 'Добавить доступ',
    room: 'Квартира',
    select_image: 'Вставить изображение',
    login_password: 'Логин/пароль',
    apartment: 'Описание квартиры',
    see_other_programs: 'Посмотреть другие программы',
    need: 'Нужен',
    not_need: 'Не нужен',
    yes: 'Да',
    no: 'Нет',
    online: 'Онлайн',
    offline: 'Офлайн',
    apply_action_tofilter_or_current_page: 'Применить действие для всех пользователей по активному фильтру или только для выбранных пользователей?',
    to_current_page: 'Для выбранных пользователей',
    to_active_filter: 'Для активного фильтра',
    applies: 'Применить',
    your_mailing_send_to_user: 'Ваша рассылка отправится пользователям в количестве',
    per: 'чел.',
    cv: 'CV',
    rec1: 'Рек-ое письмо №1',
    rec2: 'Рек-ое письмо №2',
    motiv_letter: 'Мот-ое письмо',
    ready: 'Готово',
    not_ready: 'Не готово',
    wrote: 'Написал',
    not_wrote: 'Не написал',
    history_status: 'История статуса',
    change_status: 'Изменить статус',
    change_status_success: 'Статус успешно обновлен!',
    is_need_status: 'Программе нужен статус',
    subscription_to_tgbot: 'Бот',
    date_of_payment: 'Дата оплаты'
  },
  kz: {
    course: 'Бағдарламалар',
    school_course: 'Бағдарламалар',
    my_course: 'Менің бағдарламаларым',
    schedule: 'Кесте',
    user: 'Қолданушылар',
    analytics: 'Аналитика',
    catalog: 'Каталог',
    onboard: 'Оқу',
    info: 'Ақпарат',
    admin_school: 'Админ',
    manager: 'Менеджер',
    teacher: 'Мұғалім',
    student: 'Студент',
    create_course: 'Бағдарлама қосу',
    filter: 'Фильтр',
    choose_category: 'Категорияны таңдау',
    choose_price: 'Бағаны таңдау',
    select_level: 'Деңгей таңдау',
    select_certif: 'Сертификат таңдау',
    search_by_text: 'Текст іздеу',
    count_of_stud: 'Студенттер саны',
    count_of_lessons: 'Сабақтар саны',
    students: 'студент',
    lessons: 'сабақ',
    course_name_kz: 'Қазақша бағдарлама аты',
    course_name_ru: 'Орысша бағдарлама аты',
    course_name_en: 'Ағылшынша бағдарлама аты',
    lecturer_short: 'Мұғалім-р',
    lecturer: 'Мұғалімдер',
    lecture: 'Мұғалім',
    the_students: 'Студенттер',
    the_studentss: 'Студенттер ',
    lesson: 'Сабақтар',
    categories: 'Категориялар',
    category: 'Категория',
    actions: 'Іс-әрекеттер',
    delete: 'Өшіру',
    edit: 'Өңдеу',
    view: 'Көру',
    view_more: 'Толығырақ көру',
    select_univ: 'Университет таңдаңыз!',
    my_profile: 'Менің профилім',
    exit: 'Шығу',
    back: 'Артқа',
    creating_course: 'Бағдарлама құру',
    add_category: 'Категория қосу',
    add: 'Қосу',
    del_category: 'Категория өшіру',
    del_edit_category: 'Категория өңдеу/өшіру',
    add_degree: 'Дәреже қосу',
    del_degree: 'Дәреже өшіру',
    del_edit_degree: 'Дәреже өңдеу/өшіру',
    main_info: 'Негізгі ақпарат',
    choose_lang_course: 'Бағдарламаның тілін таңдаңыз',
    instruction_add_course: 'Бағдарлама құруға арналған бейне нұсқаулық',
    course_not: 'Ешқандай бағдарлама құрылмады.',
    try_add_course: 'Оларды құрудың уақыты келді!',
    price_of_course: 'Курстың бағасы',
    price_of_study: 'Оқу бағасы',
    price_of_study_currency: 'Оқу ақысы (шетел валютасында)',
    free_course: 'Тегін бағдарлама',
    category_of_course: 'Курстың категориясы',
    display_the_course: 'Бағдарламаны каталогта көрсету',
    send_notif_course: 'Рассылка жіберу',
    add_unverified: 'Сұрыптау үшін "тексерілмеген" қосу',
    select_teacher: 'Мұғалімді таңдаңыз',
    close: 'Жабу',
    search_by_teacher: 'Мұғалім бойынша іздеу...',
    search_by_student: 'Студент бойынша іздеу...',
    selected_teachers: 'Таңдалған мұғалімдер',
    selected_students: 'Таңдалған студенттер',
    details_of_course: 'Бағдарлама бойынша мәліметтер',
    short_course_desc_ru: 'Бағдарламаның орыс тілінде қысқаша сипаттамасы',
    short_course_desc_kz: 'Бағдарламаның қазақ тілінде қысқаша сипаттамасы',
    short_course_desc_en: 'Бағдарламаның ағылшын тілінде қысқаша сипаттамасы',
    full_course_desc_ru: 'Бағдарламаның орыс тілінде толық сипаттамасы',
    full_course_desc_kz: 'Бағдарламаның қазақ тілінде толық сипаттамасы',
    full_course_desc_en: 'Бағдарламаның ағылшын тілінде толық сипаттамасы',
    show_catalog_course: 'Бағдарлама каталогында көрінеді',
    course_cover: 'Бағдарламаның мұқабасы',
    this_img_will_be_show_on_course_cover: 'Бұл мұқаба бағдарлама каталогында көрсетіледі',
    path_to_file: 'Файлға жолды көрсетіңіз немесе оны осы аймаққа сүйреңіз.',
    not_exceed: 'Файл 30 МБ аспауы керек',
    not_exceed_20: 'Файл 20 МБ аспауы керек',
    select_file: 'Файл таңдаңыз',
    media_on_page: 'Бағдарлама бетіндегі медиа (міндетті емес)',
    it_can_be_photo: 'Бұл фото, видео, демонстрациялық сабақ болуы мүмкін немесе Youtube сілтемені тіркей аласыз',
    course_created_success: 'Бағдарлама сәтті құрылды!',
    course_round_created_success: 'Раундтар сәтті сақталды!',
    course_poster_saved_success: 'Медиа сәтті сақталды!',
    course_rounds_limit: 'Раундтардың саны шектеулі!',
    category_created_success: 'Категория сәтті құрылды!',
    course_created_error: 'Қате! Ақпаратты тексеріңіз!',
    auth: 'Авторизация',
    auth_mail: 'Сіздің эл. поштаңыз',
    auth_password: 'Сіздің құпиясөзіңіз',
    auth_forget_pass: 'Құпия сөзіңізді ұмыттыңыз ба?',
    recall_password: 'Құпия сөз есіме түсті',
    auth_enter: 'Кіру',
    lang_course: 'Оқыту тілі',
    title_course: 'Текст бойынша іздеу',
    cost_course: 'Бағасы',
    recovery_password: 'Құпия сөзді қалпына келтіру',
    recovery: 'Қалпына келтіру',
    mail_on: 'Хат сіздің поштаңызда!',
    confirm_reset_password: 'Жасалған құпия сөз электрондық поштаңызға жіберілді!',
    go_back: 'Артқа қайту',
    fill_fields: 'Осы өрісті толтырыңыз',
    select_all: 'Барлығын таңдау',
    remove_all: 'Барлығын алып тастау',
    category_name: 'Категория аты',
    about_course: 'Бағдарлама туралы',
    lesson_list: 'Сабақтар тізімі',
    course_analytic: 'Курстың аналитикасы',
    edit_course: 'Программаны өңдеу',
    edit_programs: 'Программаны өңдеу',
    delete_course: 'Курсты өшіру',
    delete_lesson: 'Сабақты өшіру',
    delete_group: 'Топты өшіру',
    categories_of_course: 'Курс категориялары',
    features_of_course: 'Артықшылықтары',
    sure_remove_entry: 'Бұл жазбаны шынымен жойғыңыз келе ме?',
    sure_remove_lesson_schedule: 'Бұл сабақ кестесін алып тастағыңыз келетініне сенімдісіз бе',
    sure_remove_work_schedule: 'Бұл жұмыс кестесін алып тастағыңыз келетініне сенімдісіз бе',
    sure_remove_course: 'Бұл программаны алып тастағыңыз келетініне сенімдісіз бе',
    sure_delete_course: 'Бұл программаны өшіргіңіз келетініне сенімдісіз бе',
    sure_delete_selected_course: 'Таңдалған программаларды өшіргіңіз келетініне сенімдісіз бе',
    sure_delete_lesson: 'Бұл сабақты өшіргіңіз келетініне сенімдісіз бе',
    sure_delete_group: 'Бұл топты өшіргіңіз келетініне сенімдісіз бе',
    sure_delete_user: 'Бұл қолданушыны өшіргіңіз келетініне сенімдісіз бе',
    agree_del: 'Иә, өшіру',
    cancel: 'Болдырмау',
    all_lessons: 'Барлық сабақтар',
    all_sections: 'Барлық бөлімдер',
    create_lessons: 'Бөлім құру',
    vido_lesson: 'Бейне сабақ',
    test_lesson: 'Тест',
    live_lesson: 'Live сабақ',
    duration: 'Ұзақтығы',
    minute: 'мин',
    preview: 'Алдын ала көру',
    no_lesson: 'Сабақ жоқ.',
    add_teacher: 'Мұғалімді қосу',
    add_student: 'Студентті қосу',
    groups: 'Топтар',
    courses_teacher: 'Бағдарлама мұғалімдері',
    courses_student: 'Бағдарлама студенттері',
    fullname: 'Аты-жөні',
    mail: 'Эл. пошта',
    phone: 'Телефон',
    birth: 'Туған күн',
    courses_group: 'Бағдарлама топтары',
    add_group: 'Топ құру',
    name_group: 'Топ аты',
    edit_group: 'Топты өңдеу',
    saturday: 'Сенбі',
    monday: 'Дүйсенбі',
    tuesday: 'Сейсенбі',
    wednesday: 'Сәрсенбі',
    thursday: 'Бейсенбі',
    friday: 'Жұма',
    sunday: 'Жексенбі',
    all_user: 'Барлық қолданушылар',
    select_instructor: 'Мұғалімді таңдаңыз',
    save: 'Сақтау',
    seacrh_teacher: 'Мұғалімді іздеу',
    select_student: 'Студентті таңдаңыз',
    search_student: 'Студентті іздеу',
    delete_teacher: 'Мұғалімді өшіру',
    delete_student: 'Студентті өшіру',
    sure_remove_teacher_from_course: 'Мұғалімді шығаруға сенімдісіз бе бағдарламадан',
    agree_remove: 'Иә, алып тастау',
    sure_remove_student_from_group: 'Студентті шығаруға сенімдісіз бе группадан',
    create_group: 'Топ құру',
    instruction_add_group: 'Топ құруға арналған бейне нұсқау',
    group_name: 'Топ аты',
    group_teacher: 'Топ мұғалімі',
    select_students: 'Студентті таңдау',
    group_type: 'Группаның типі',
    trial_lesson: 'Сынақ сабағы',
    indiv_lesson: 'Жеке сабақ',
    group_lesson: 'Топтық сабақтар',
    pair_lesson: 'Жұптастырылған сабақ',
    groups_schedule: 'Топтық жұмыс кестесі',
    no_schedule: 'Қазіргі уақытта кесте жоқ',
    add_lesson: 'Сабақ қосу',
    select_lesson_format: 'Сабақ форматын таңдаңыз',
    day_of_week: 'Апта күні',
    start_time: 'Басталу уақыты',
    end_time: 'Аяқталу уақыты',
    amount_of_course: 'Бағд. саны',
    amount_of_lesson: 'Сабақ саны',
    last_login: 'Соңғы кіру уақыты (күндер)',
    add_user: 'Пайдаланушы қосу',
    role: 'Рөл',
    action: 'Іс-әрекет',
    reset_filter: 'Фильтрді тазалау',
    del_user: 'Қолданушыны өшіру',
    video_instruction_create_user: 'Қолданушыны құруға арналған бейне нұсқаулық',
    creating_user: 'Қолданушыны құру',
    select_role: 'Рөлді таңдаңыз',
    create_user: 'Қолданушыны құру',
    name: 'Аты',
    grant: 'Грант',
    last_name: 'Тегі',
    password: 'Құпиясөз',
    generate: 'Генерация',
    email_has_already: 'Пошта базада бар!',
    change_photo: 'Фото өзгерту',
    teachers_courses: 'Мұғалімнің курстары',
    work_schedule: 'Жұмыс кестесі',
    add_course: 'Курс қосу',
    add_graph: 'График қосу',
    select_course: 'Курсты таңдаңыз',
    search_by_course: 'Курсты іздеу',
    remove_course: 'Курсты алып тастау',
    add_work_schedule: 'Жұмыс кестесін қосыңыз',
    add_lesson_schedule: 'Сабақ кестесін қосыңыз',
    choose_suit_schedule: 'Дұрыс күн тәртібін таңдаңыз',
    start: 'Бастау',
    end: 'Аяқталу',
    remove_work_schedule: 'Жұмыс кестесін алып тастау',
    main: 'Негізгі',
    communications: 'Байланыс',
    mailing: 'Рассылкалар',
    comment: 'Пікірлер',
    documents: 'Құжаттар',
    document: 'Құжат',
    filter_by_date: 'Күні бойынша фильтр:',
    from: 'бастап',
    to: 'дейін',
    your_comment: 'Сіздің пікіріңіз осында',
    add_comments: 'Пікір қосу',
    balance: 'Студент балансы',
    students_course: 'Студент курсы',
    payment: 'Төлем',
    lessonss: 'сабақ',
    add_pay: 'Төлем қосу',
    show_p_history: 'Төлемдерді көрсету',
    study_schedule: 'Оқу кестесі',
    remove_lesson_schedule: 'Сабақ кестесін алып тастау',
    lesson_format: 'Сабақтың форматы',
    lesson_type: 'Сабақтың типі',
    select_format_lesson: 'Сабақтың форматын таңдаңыз',
    select_type_lesson: 'Сабақтың типін таңдаңыз',
    select_schedule: 'Оқу кестесін таңдаңыз',
    add_schedule: 'Оқу кестесін қосу',
    search_payment: 'Мәтін бойынша төлемді іздеңіз',
    payment_history: 'Төлем тарихы',
    payment_date: 'Төлем күні',
    recommend_teacher: 'Ұсынылатын мұғалімдер',
    video_instruction_create_lesson: 'Бөлімді құруға арналған бейне нұсқаулық',
    create_lesson: 'Бөлім құру',
    info_about_lesson: 'Бөлім туралы ақпарат',
    constructor: 'Тапсырма конструкторы',
    name_of_lesson: 'Бөлімнің атауы',
    video_material: 'Бейне материал',
    select_video_display_lesson: 'Бөлімде көрсетілетін бейнені таңдаңыз',
    lesson_section: 'Бөлімдер',
    section_name: 'Бөлім атауы',
    add_section: 'Бөлім қосу',
    order: 'Реті',
    create: 'Құру',
    task_test_type: 'Сынама',
    task_media_test_type: 'Сынама суретпен',
    task_media_type: 'Медиа файлды салу',
    task_youtube_type: 'Youtube сілтемесін салу',
    task_word_enter_type: 'Сөздерді мәтінге салу',
    task_word_enter_select_type: 'Нұсқалармен сөздерді салыңыз',
    task_table_enter_type: 'Кестеге сөзді салу',
    task_table_enter_select_type: 'Кестеге нұсқаларды салу',
    task_find_error_type: 'Мәтіндегі қателерді табу',
    task_text_type: 'Мәтіндік тапсырма',
    task_compare_type: 'Сөздерді салыстыр',
    task_essay_type: 'Эссе жазу',
    task_file_check_type: 'Студенттік файлдарды тексеру',
    task_file_add_type: 'Файлды сабаққа  тіркеу',
    task_select_one_type: 'Нұсқалардан бірін таңдау',
    create_task_lesson: 'Сабаққа тапсырма қосу',
    task_type: 'Тапсырма типі',
    answer_options: 'Жауап нұсқалары',
    note_correct_answer: 'Дұрыс жауаптарды белгілеңіз',
    immediately_show_correct_answer: 'Студентке дұрыс жауап көрсету',
    option: 'Нұсқа',
    add_options: 'Нұсқа қосу',
    save_task: 'Тапсырма сақтау',
    correct_answer: 'Бұл дұрыс нұсқа',
    answer_text: 'Сұрақтың тексті',
    add_file: 'Файлды қосу',
    would_be_ppt_media: 'Бұл презентация немесе бейне сабақ болуы мүмкін',
    recommend_file: 'Файл бойынша ұсыныстар',
    img_format: 'Сурет форматтары',
    video_format: 'Бейне форматтары',
    video_audio_format: 'Бейне/аудио форматтары',
    audio_format: 'Аудио форматтары',
    file_format: 'Файл форматтары',
    if_media_more: 'Егер файл өлшемінен үлкен болса',
    mb: 'Мб',
    media_recom: 'Кішірек кескіндер компьютер дисплейінде жылдам жүктеледі және көрсетіледі',
    usefull_links: 'Пайдалы сілтемелер:',
    ok: 'Жақсы',
    img_compression: 'Кескінді қысу',
    video_compression: 'Бейнені қысу',
    audio_compression: 'Аудионы қысу',
    step_1_2: '1/2 қадам. Тапсырма мәтіні',
    step_2_2: '2/2 қадам. Енгізілетін сөздерді белгілеңіз',
    save_text: 'Мәтінді сақтау',
    back_to_text: 'Мәтінге оралу',
    text_your_text: 'Мәтініңізді осы жерге жазыңыз, содан кейін бос орындарға сөздер қосуға болады',
    add_pair: 'Жұптарды құру',
    create_pair: 'Жұптарды қосу',
    field_essay: 'Студенттe эссе жазу өрісі болады',
    field_load: 'Студенттe файлды жүктеп салу өрісі болады',
    pin: 'Бекіту',
    name_of_task: 'Тапсырма атауы',
    pin_student_file: 'Сіз студенттерге жүктеп алу үшін файлдарды қоса аласыз',
    step_1_2_table: '1/2 қадам. Кестені жасаңыз және толтырыңыз',
    strings: 'Жолдар',
    columns: 'Бағандар',
    error_options: 'Нұсқа қатесі',
    add_task: 'Тапсырма қосу',
    mulitmedia: 'Мультимедиа',
    fill_all_gaps: 'Барлық бос орындарды дұрыс деп санайтын сөздермен толтыру керек',
    essay_text: 'Мәтін',
    your_note: 'Сіздің жазбаңыз',
    delete_task: 'Тапсырманы жою',
    sure_delete_task: 'Бұл тапсырманы шынымен жойғыңыз келе ме',
    word_1: '1-сөз',
    word_2: '2-сөз',
    cannot_change: 'Бағандар мен жолдар санын өзгертуге болмайды!',
    not_found_query: 'Кешіріңіз, сіздің іздеуіңіз бойынша нәтижелер табылмады!',
    catalog_empty: 'Каталог қазіргі уақытта бос',
    user_empty: 'Пайдаланушылар тізімі қазір бос',
    edit_lesson: 'Бөлімді өңдеу',
    edit_lessons: 'Бөлімдерді өңдеу',
    my_balance: 'Менің қалдығым',
    start_lesson: 'Сабақты бастау',
    start_course: 'Курсты бастау',
    buy_course: 'Сатып алу',
    course_of_lang: 'Курс тілі',
    del_section: 'Бөлімді өшіру',
    edit_section: 'Бөлімді өңдеу',
    sure_delete_section: 'Бөлімді өшіргіңіз келетініне сенімдісіз бе',
    exercises_empty: 'Қазіргі уақытта тапсырмаларға арналған бөлім жоқ',
    end_lesson: 'Сабақты аяқтау',
    a_lesson: 'Сабақ',
    answers: 'Жауаптар',
    sure_finish_lesson: 'Сабақты аяқтағыңыз келетініне сенімдісіз бе',
    agree_finish: 'Иә, аяқталды',
    interactive_board: 'Интерактивті тақта',
    back_to_tasks: 'Тапсырмаларға оралу',
    edit_work_schedule: 'Жұмыс кестесін өңдеу',
    reset_answers: 'Жауаптарды ысыру',
    check: 'Тексеру',
    a_course: 'Курс',
    clear: 'Тазалау',
    doesnt_support: 'Сіздің браузеріңіз бұл файлды қолдамайды',
    students_answer: 'Студенттердің жауаптары',
    answer_no: 'Студенттердің жауабы жоқ',
    choose_group: 'Топты таңдаңыз',
    empty_payment_history: 'Төлем тарихы жоқ',
    select_section: 'Бөлімді таңдаңыз',
    a_group: 'Топ',
    an_action: 'Әрекет',
    write: 'Жазу',
    correct: 'дұрыс',
    the_tasks: 'тапсырма',
    show_answers: 'Жауаптарды көрсету',
    hide_answers: 'Жауаптарды жасыру',
    correct_answers: 'Дұрыс жауаптар',
    a_correct_answer: 'Дұрыс жауап',
    students_answers: 'Студент жауаптары',
    a_students_answer: 'Студент жауабы',
    send_letter: 'Хат жіберу',
    send: 'Жіберу',
    subject_mail: 'Хат тақырыбы',
    text_mail: 'Хат тексті',
    date: 'дата',
    recommend: 'Ұсынылады',
    regular_lesson: 'Тұрақты сабақтар',
    trial_lessons: 'Сынақ сабақтары',
    empty_data_list: 'Қазіргі уақытта ақпарат жоқ',
    empty_document_list: 'Қазіргі уақытта құжаттар жоқ',
    empty_comment_list: 'Қазіргі уақытта пікірлер жоқ',
    empty_schedule_list: 'Қазіргі уақытта оқу кестесі жоқ',
    edit_lesson_schedule: 'Оқу кестесін өңдеңіз',
    today: 'Бүгін',
    week: 'апта',
    day: 'күн',
    my_settings: 'Менің параметрлерім',
    personal_settings: 'Жеке параметрлер',
    school_settings: 'Мектеп параметрлері',
    newsletter: 'Рассылка',
    my_tariff: 'Менің тарифім',
    country: 'Мемлекет',
    school_name: 'Мектеп аты',
    school_desc: 'Мектеп сипаттамасы',
    school_adress: 'Мектеп мекенжайы',
    school_currency: 'Мектеп валютасы',
    school_allowed_cancel: 'Айына рұқсат етілген бас тартулар саны',
    school_allowed_cancel_for: 'Бас тартуға рұқсат етілген',
    work_time: 'Жұмыс сағаттары',
    auto_lesson: 'Кешегі сабақтарды автоматты түрде өткізу',
    minute_f: 'минут',
    hour: 'сағат',
    a_hour: 'сағат',
    school_allowed_transfer: 'Айына рұқсат етілген аударымдар саны',
    school_allowed_transfer_for: 'Ауыстыруға рұқсат етілген',
    hi_test: 'Сәлем, Тест!',
    notif_lesson: 'Сізде {курс аты} курсы бойынша {lesson.time_from} {студент аты} мұғалімімен сабақ бар екенін еске саламыз',
    wish_lesson: 'Сабақтан ләззат алуларыңызға тілектеспіз',
    if_cant_notif: 'Сәтсіз болса, жеке кабинетте сабақтың уақытын өзгертуге немесе одан бас тартуға болады',
    with_scincerly: '– Құрметпен, {мектеп аты} командасы!',
    notification_lesson: 'Сабақты еске түсіру',
    new_mailing: 'Жаңа ақпаратты жіберу',
    create_mailing: 'Ақпаратты құру',
    recipients: 'Алушылар',
    over_period_time: 'Белгілі бір уақыт аралығында',
    all: 'Барлық',
    text_mailing: 'Рассылка мәтіні',
    trial: 'Сынақ',
    indiv: 'Жеке',
    group: 'Топ',
    pair: 'Жұп',
    change: 'Өзгерту',
    add_mailing: 'Рассылка қосу',
    delete_notif: 'Еске салғышты жою',
    sure_notif_delete: 'Еске салғышты жойғыңыз келетініне сенімдісіз бе',
    update_tariff: 'Тариф жаңарту',
    payment_a_history: 'Төлемдер тарихы',
    active: 'Белсенді',
    no_active: 'Белсенді емес',
    monthly_payment: 'Ай сайынғы төлем',
    number_students: 'Студенттер саны',
    next_payment: 'Келесі төлем',
    number_months: 'Айлар саны',
    total: 'Барлығы:',
    pay: 'Төлеу',
    sum: 'Сумма',
    transactions: 'Транзакции №',
    description: 'Сипаттама',
    add_photo: 'Фото қосу',
    lesson_done: 'Сабақ аяқталды',
    reschedule_lesson: 'Сабақты қайта жоспарлау',
    cancel_lesson: 'Сабақтан бас тарту',
    return: 'Қайтару',
    filter_by_schedule: 'Кесте бойынша фильтр',
    by_lesson: 'Сабақтар бойынша',
    by_students: 'Студенттер бойынша',
    empty_analytic: 'Қазіргі уақытта аналитика бос.',
    empty_analytic_1: 'Аналитика пайда болуы үшін мектебіңізді дамытуды бастаңыз',
    graphs: 'Графиктер',
    report: 'Баяндама',
    jan: 'ҚАҢ',
    feb: 'АҚП',
    mar: 'НАУ',
    apr: 'СӘУ',
    may: 'МАМ',
    jun: 'МАУ',
    jul: 'ШІЛ',
    avg: 'ТАМ',
    sen: 'ҚЫР',
    okt: 'ҚАЗ',
    nov: 'ҚАР',
    dec: 'ЖЕЛ',
    january: 'қаңтар',
    february: 'ақпан',
    march: 'наурыз',
    april: 'сәуір',
    mays: 'мамыр',
    june: 'маусым',
    july: 'шілде',
    august: 'тамыз',
    september: 'қыркүйек',
    october: 'қазан',
    november: 'қараша',
    december: 'желтоқсан',
    select_by_course: 'Бағдарлама бойынша таңдау',
    select_by_status: 'Статус бойынша таңдау',
    amount_of_purchase: 'Сатып алулар саны',
    sum_of_sales: 'Сату сомасы',
    search__student: 'Студентті іздеу',
    search__teacher: 'Мұғалімді іздеу',
    more: 'Толығырақ',
    purchases: 'Сатып алулар',
    selling: 'Сатылым',
    done: 'Жасалды',
    progress: 'Прогресс',
    current_month: 'Ағымдағы ай',
    conducted_lesson: 'Өткізілген сабақтар',
    canceled_lesson: 'Жойылған сабақтар',
    transfered_lesson: 'Ауыстырылған сабақтар',
    statistic_type_lesson: 'Сабақтың түрі бойынша статистика',
    individual_lesson: 'Жеке сабақтар',
    conducted: 'Өткізілген',
    canceled: 'Бас тартылды',
    postponed: 'Кейінге қалдырылды',
    implementation: 'Іске асыру',
    select_by_student: 'Студентті таңдау',
    a_lessons: 'сабақ',
    attendance_of_lessons: 'Сабаққа қатысу',
    effectiveness_of_lessons: 'Сабақтың тиімділігі',
    total_lessons: 'Жалпы сабақтар',
    exercises: 'Жаттығулар',
    a_correct: 'Дұрыс',
    incorrect: 'Қате',
    correct_done: 'Дұрыс / Жасалды',
    all_right_reserved: 'Барлық құқықтар қорғалған',
    personal_data: 'Жеке деректерді өңдеу',
    confidentiality: 'Құпиялылық',
    terms_of_use: 'Пайдалану шарттары',
    online_payments_security: 'Онлайн төлем қауіпсіздігі',
    open: 'Ашу',
    for_questions: 'Сұрақтар үшін',
    fill_category_name: 'Категория атауын толтырыңыз',
    fill_course_name: 'Курстың атын толтырыңыз',
    fill_category: 'Категория таңдаңыз!',
    fill_teacher: 'Мұғалімді  таңдаңыз!',
    fill_short_desc: 'Курстың қысқаша сипаттамасын толтырыңыз!',
    fill_full_desc: 'Толық курс сипаттамасын толтырыңыз!',
    fill_poster: 'Курстың мұқабасын таңдаңыз!',
    course_update_media_upload: 'Курс сәтті жаңартылды! Күте тұрыңыз, медиа файл жүктелуде!',
    courses_update: 'Бағдарламалар сәтті жаңартылды!',
    lessons_update: 'Сабақтар сәтті жаңартылды!',
    media_uploaded: 'Медиа файл сәтті жүктелді!',
    round_updated: 'Күн сәтті жаңартылды!',
    round_deleted: 'Күн сәтті өшірілді!',
    fill_media: 'Медиа файл таңдаңыз!',
    fill_lesson_name: 'Сабақтың атын толтырыңыз!',
    lesson_created_success: 'Сабақ сәтті құрылды!',
    fill_duration: 'Ұзақтықты толтырыңыз!',
    fill_order: 'Кезекті толтырыңыз!',
    lesson_edited_success: 'Сабақ сәтті жаңартылды!',
    fill_name: 'Атын толтырыңыз!',
    fill_last_name: 'Фамилияны толтырыңыз!',
    fill_phone: 'Телефон нөмерін толтырыңыз!',
    profile_data_update: 'Пайдаланушының жеке деректері жаңартылды!',
    avatar_apdate: 'Аватар жаңартылды!',
    fill_school_name: 'Мектептің атын толтырыңыз!',
    fill_adress_name: 'Мектептің электрондық адресін толтырыңыз!',
    fill_allow_cancel: 'Айына рұқсат етілген бас тарту санын көрсетіңіз!',
    fill_allow_transfer: 'Айына рұқсат етілген аударымдар санын көрсетіңіз!',
    success_school_name: 'Мектеп деректері жаңартылды!',
    fill_email: 'Эл.поштаны толтырыңыз!',
    fill_password: 'Құпиясөзді толтырыңыз!',
    user_created_success: 'Пайдаланушы сәтті құрылды!',
    user_updated_success: 'Пайдаланушы сәтті жаңартылды!',
    user_deleted_success: 'Пайдаланушы сәтті өшірілді!',
    toast_work_schedule: 'Жұмыс кестесі қосылды!',
    toast_work_schedule_edit: 'Жұмыс кестесі жаңартылды!',
    fill_course: 'Курсты таңдаңыз!',
    fill_price: 'Құнын көрсетіңіз!',
    fill_count_lesson: 'Сабақтар санын көрсетіңіз!',
    toast_course_add: 'Курс қосылды!',
    toast_course_edit: 'Пайдаланушы балансының деректері жаңартылды!',
    toast_study_schedule: 'Оқу кестесі қосылды!',
    toast_study_schedule_edit: 'Оқу кестесі жаңартылды!',
    toast_format: 'Сабақ форматын таңдаңыз!',
    toast_type: 'Сабақтың типін таңдаңыз!',
    toast_group_name: 'Топ атын толтырыңыз!',
    toast_students: 'Студентті таңдаңыз!',
    toast_group_create: 'Топ сәтті құрылды!',
    toast_group_update: 'Топ сәтті жаңартылды!',
    toast_mail_send: 'Хат сәтті жіберілді!',
    toast_mail_send_error: 'Қате! Пайдаланушының электрондық поштасын тексеріңіз!',
    toast_section_name: 'Бөлім атын толтырыңыз!',
    toast_section_order: 'Бөлім ретін толтырыңыз!',
    toast_section_add: 'Бөлім қосылды!',
    toast_section_name_edit: 'Бөлім жайында ақпарат өзгертілді!',
    toast_section_delete: 'Бөлім сәтті жойылды!',
    toast_task_added: 'Тапсырма сәтті қосылды!',
    toast_test_task: 'Тапсырманың атын толтырыңыз!',
    toast_test_task_answer: 'Сұрақ мәтінін толтырыңыз!',
    toast_test_task_options: 'Нұсқа мәтінін толтырыңыз!',
    toast_test_task_options_add: 'Нұсқа қосыңыз!',
    toast_test_task_options_correct: 'Дұрыс нұсқаны таңдаңыз!',
    toast_task_table_r_c: 'Бағандар мен жолдардың санын көрсетіңіз!',
    toast_test_task_text: 'Тапсырманың мәтінін толтырыңыз!',
    toast_task_connect_add: 'Жұп қосыңыз!',
    toast_task_connect_word: 'Сөздерді толтырыңыз!',
    task_updated_success: 'Тапсырма сәтті жаңартылды!',
    something_went_wrong: 'Бірдеңе дұрыс болмады, қайталап көріңіз...',
    toast_notif_add: 'Хабарлама сәтті қосылды!',
    toast_notif_delete: 'Хабарлама сәтті өшірілді!',
    toast_comment_added: 'Пікір сәтті қосылды!',
    toast_comment_field: 'Пікір жолын толтырыңыз!',
    toast_avail_course_delete: 'Курс сәтті өшірілді!',
    toast_avail_course_added: 'Курс сәтті қосылды!',
    toast_lesson_schedule_delete: 'Сабақ кестесі сәтті өшірілді!',
    empty_notif_list: 'Қазіргі уақытта хабарламалар жоқ',
    toast_work_schedule_delete: 'Жұмыс кестесі сәтті өшірілді!',
    toast_lesson_delete: 'Сабақ сәтті өшірілді!',
    toast_lesson_copy: 'Сабақ сәтті көшірілді!',
    toast_teacher_course_remove: 'Мұғалім тізімнен сәтті шығарылды!',
    toast_teacher_course_added: 'Мұғалім тізімге сәтті қосылды!',
    toast_student_course_remove: 'Студент тізімнен сәтті шығарылды!',
    toast_student_course_added: 'Студент тізімге сәтті қосылды!',
    empty_course: 'Қазіргі уақытта курс жоқ.',
    my_group: 'Менің группаларым',
    my_groups_student: 'Менің тобымдағы студенттер',
    search: 'Іздеу',
    no_matches: 'Сәйкестік жоқ',
    kazakh: 'Қазақ',
    rus: 'Орыс',
    eng: 'Ағылшын',
    turk: 'Түрік',
    nem: 'Неміс',
    venger: 'Венгер',
    toast_del_category_success: 'Санат сәтті жойылды!',
    toast_edit_category_success: 'Санат сәтті өңделді!',
    copy: 'Курсты көшіру',
    copy_lesson: 'Сабақ көшіру',
    toast_copy_success: 'Курс сәтті көшірілді!',
    toast_empty_program: 'Бағдарламада бөлім жоқ!',
    degree_name: 'Дәреже',
    toast_degree_created_success: 'Дәреже сәтті құрылды!',
    toast_degree_deleted_success: 'Дәреже сәтті жойылды!',
    toast_degree_upded_success: 'Дәреже сәтті өңделді!',
    select_degree: 'Дәрежені таңдаңыз',
    select_format: 'Формат таңдаңыз!',
    select_range_data: 'Күн аралығын таңдаңыз',
    offer: 'Ұсыныс',
    i_have_acc: 'Менде аккаунт бар',
    i_have_no_acc: 'Менде аккаунт жоқ',
    account_create: 'Аккаунт қосылды',
    registrate: 'Тіркеу',
    italy: 'Италия',
    greece: 'Грек',
    chez: 'Чех',
    terms: 'Уақыты',
    del_format: 'Формат өшіру',
    del_edit_format: 'Формат өшіру/өңдеу',
    add_format: 'Формат қосу',
    fill_format: 'Форматтың атын толтырыңыз',
    name_format: 'Формат атауы',
    course_format: 'Формат',
    toast_format_created_success: 'Формат сәтті қосылды!',
    toast_format_deleted_success: 'Формат сәтті өшірілді!',
    toast_format_upded_success: 'Формат сәтті өңделді!',
    format: 'Формат',
    univer: 'Университет',
    del_univer: 'Университет өшіру',
    del_edit_univer: 'Университет өңдеу/өшіру',
    add_univer: 'Университет қосу',
    fill_univer: 'Университет атын толтырыңыз',
    name_univer: 'Университет атауы',
    toast_univer_created_success: 'Университет сәтті қосылды!',
    toast_univer_deleted_success: 'Университет сәтті өшірілді!',
    toast_univer_upded_success: 'Университет сәтті өңделді!',
    speciality: 'Факультет',
    del_special: 'Факультет өшіру',
    del_edit_special: 'Факультет өңдеу/өшіру',
    add_special: 'Факультет қосу',
    fill_special: 'Факультет атын толтырыңыз',
    name_special: 'Факультет атауы',
    toast_special_created_success: 'Факультет сәтті қосылды!',
    toast_special_deleted_success: 'Факультет сәтті өшірілді!',
    toast_special_upded_success: 'Факультет сәтті өңделді!',
    del_city: 'Қала өшіру',
    del_edit_city: 'Өала өңдеу/өшіру',
    add_city: 'Қала қосу',
    fill_city: 'Қаланың атын толтырыңыз',
    name_city: 'Қала атауы',
    course_city: 'Қала',
    toast_city_created_success: 'Қала сәтті қосылды!',
    toast_city_deleted_success: 'Қала сәтті өшірілді!',
    toast_city_upded_success: 'Қала сәтті өңделді!',
    city: 'Қала',
    poster_url: 'Youtube-тегі бейнеге сілтеме',
    link_youtube: 'Youtube-тен бейне сілтемесін тіркеңіз',
    deadlines_for_submission: 'Тапсыру мерзімдері',
    submission_fee: 'Жіберу ақысы',
    submission_fee_tenge: 'Жіберу ақысы (теңге)',
    open_rounds: 'Тапсыру мүмкін',
    poster_media: 'Өз медиа файлыңызды таңдаңыз',
    all_exercise_should_be_done: 'Барлық тапсырмаларды орындаңыз',
    extended_filter: 'Қосымша фильтр',
    hide: 'Жасыру',
    how_it_works: 'Excourse қалай жұмыс істейді?',
    text_onboard: 'Кішкене жаттығудан өтіп, платформаны оңай және қарапайым пайдаланыңыз!',
    later: 'Кейінірек',
    get_trained: 'Жаттығудан өтіңіз',
    select_training_section: 'Жаттығу бөлімін таңдаңыз',
    display_course_catalog: 'Каталог барлық қолжетімді бағдарламаларды көрсетеді',
    educate: 'Білімі',
    educate_certfifcate: 'Білімі (сертификаттар)',
    certificate: 'Сертификаттар',
    add_edu: 'Білімді қосу',
    middle_school: 'Орта білім (мектеп, колледж)',
    school: 'Мектеп',
    college: 'Колледж',
    bachelor: 'Бакалавриат',
    magistracy: 'Магистратура',
    phd: 'Докторантура',
    profession: 'Мамандық',
    profession_name: 'Мамандық атауы',
    period_of_study: 'Оқу мерзімі (жыл)',
    ends: 'Соңы',
    avg_rate: 'Орташа баға (бағалар, GPA және т.б.)',
    form_of_rate: 'Бағалау формасы (GPA, балл немесе басқа)',
    rate: 'Баллы',
    fill_profession_name: 'Мамандық атауын толтырыңыз!',
    fill_start_period: 'Оқу кезеңінің басын толтырыңыз!',
    fill_form_of_rate: 'Баға формасын толтырыңыз!',
    del_edu: 'Білімді өшіру',
    sure_del_edu: 'Бұл білім дәрежесін өшіріп тастағыңыз келетініне сенімдісіз бе',
    toast_edu_add_success: 'Білім дәрежесі сәтті қосылды!',
    toast_edu_edit_success: 'Білім дәрежесі сәтті өзгертілді!',
    toast_edu_delete_success: 'Білім дәрежесі сәтті өшірілді!',
    fill_certif_name: 'Сертификаттың атын толтырыңыз!',
    add_certif: 'Сертификат қосу',
    a_certificate: 'Сертификат',
    certif_name: 'Аты (IELTS, TOEFL)',
    total_score: 'Жалпы балл',
    toast_certif_add_success: 'Сертификат сәтті қосылды!',
    toast_certif_edit_success: 'Сертификат сәтті өзгертілді!',
    toast_certif_delete_success: 'Сертификат сәтті өшірілді!',
    del_certif: 'Сертификат өшіру',
    sure_del_certif: 'Осы сертификатты өшіргіңіз келетініне сенімдісіз бе',
    if_check_all_task_done: 'Бөлімдегі барлық тапсырмалардың орындалғанын тексеру',
    second_hint: 'Мұнда сіздің жеке параметрлеріңіз бар',
    first_hint: 'Сатып алынған бағдарламаларды менің бағдарламаларым бөлімінен көре аласыз',
    third_hint: 'Осы жерде сіздің оқу кестеңіз',
    fourth_hint: 'Бағдарламаларыңыздың аналитикасы осы жерде көруге болады',
    select_learn_select: 'Жаттығу бөлімін таңдаңыз',
    one_section: 'Excourse-та қандай бөлімдер бар?',
    onboard_button: 'Видеомен толық таныстым',
    three_section: 'Деректерді/құжаттарды қалай және қайда толтыру және тіркеу керек?',
    two_section: 'Профилімді қай жерде реттей аламын?',
    press_my_profile: 'Мұнда басыңыз және «Менің профилім» таңдаңыз',
    super: 'Керемет!',
    onboard_done: 'Сіз платформада жаттығудан өттіңіз!',
    next: 'Келесі',
    fill_edu_and_certif: '«Білім» және «Сертификаттар» бөлімдерінде білім және сертификаттар туралы мәліметтерді толтырайық!',
    requisites: 'Реквизит',
    thank_you: 'Сатып алғаныңыз үшін рахмет!',
    numb_pay: 'Тапсырыс нөмірі',
    country_category: 'Мемлекет/Категория',
    search_pr_city_univ: 'Іздеу (мамандық, қала, университет)',
    free: 'Тегін',
    open_access: 'Рұқсат беру',
    starts: 'Бастау',
    enter_a_code: 'Кодты енгізіңіз',
    sms_first: '',
    sms_second: 'почтасына код жіберілді. Мұнда енгізіңіз:',
    sms_third: 'нөміріне',
    catalog_of_scecial: 'Мамандықтар каталогы',
    send_again: 'Кодты қайта жіберу',
    send_again_2: '',
    number_phone_8: 'Телефон нөмірі "+7" белгісінен басталуы керек',
    number_input_full: 'Телефонды толтырыңыз',
    send_code: 'Код жіберу',
    faq: 'Жиі қойылатын сұрақтар',
    faq_menu: 'FAQ',
    select_lesson: 'Сабақты таңдаңыз',
    select_es_list: 'Тапсырма бетін таңдаңыз',
    empty_exercise_list: 'Қазіргі уақытта тапсырмалар жоқ',
    date_joined: 'Тіркелген күн',
    should_add_timer: 'Бөлім таймермен',
    timer: 'Таймер',
    timer_stop: 'Уақыт бітті!',
    start_exam: 'Экзаменді бастау',
    finish_exam: 'Экзаменді аяқтау',
    u_r_going_to_start: 'Сіз тапсырмаларды орындауға кірісесіз',
    result: 'Нәтиже',
    time: 'Уақыт',
    video_warn: 'Бейне оқулықтың уақытша болмағаны үшін кешірім сұраймыз! Барлық пайдаланушылар, жүктеп алғаннан кейін, бейне сабаққа қол жеткізе алады!',
    notes: 'Ескерту',
    checked: 'Тексерілді',
    tgram: 'Телеграм',
    toast_hw_sent_success: 'Тапсырма жауаптары сәтті жіберілді!',
    all_checked: 'Барлығы тексерілді',
    no_checked: 'Тексерілмеген',
    tasks: 'Тапсырмалар',
    round_should_be_today_or: 'Ескерту: "Дейін" күні өткен күн болмауы керек',
    add_date: 'Күн қосу',
    multi_media: 'Медиа файлдар',
    from_comp: 'Компьютерден мультимедианы жүктеңіз',
    should_select_file: 'Файлды таңдаңыз!',
    selected_media: 'Таңдалған файлдар',
    count_of_massive_course: 'Саны шегіне жетті!',
    count_of_massive_course_select_one: 'Бағдарламаларды бір-бірден таңдаңыз!',
    count_of_massive_section_select_one: 'Бөлімдерді бір-бірден таңдаңыз!',
    not_checked: 'Тексерілмеген',
    by_programms: 'Бағдарлама бойынша',
    by_education: 'Білімі бойынша',
    by_status: 'Статус бойынша',
    edit_date: 'Өңдеу күні бойынша фильтр:',
    edit_date_table: 'Өңд-н күні',
    min_percent: 'Ең төменгі шекті пайызды орнату',
    not_task_with_grade_count: 'Дұрыс жауаптарды есептейтін тапсырмалар жоқ',
    dont_pass: 'Сізге өтуге қажет балл жинау керек. Пайыз жоғары болуы керек:',
    ielts: 'IELTS',
    toefl: 'TOEFL',
    fce: 'FCE',
    cae: 'CAE',
    cpe: 'CPE',
    bec: 'BEC',
    select_city: 'Қаланы таңдаңыз!',
    my_language_know: 'Тілді білу деңгейі',
    my_language_know_add: 'Шет тілін қосыңыз',
    my_language_know_lang_input: 'Тіл',
    my_language_know_lang_input_select: 'Тілді таңдау',
    my_language_know_lang_input_level: 'Деңгей',
    fill_lang_level_name: 'Тілді таңдаңыз!',
    fill_lang_level: 'Тіл деңгейін таңдаңыз!',
    my_language_added_success: 'Ақпарат сәтті сақталды!',
    my_language_del_success: 'Ақпарат сәтті өшірілді!',
    list_of_edu: 'Білімі туралы ақпарат',
    next_btn: 'Келесі',
    teaching: 'Білім',
    language_edu: 'Тіл білімі',
    select_start_year: 'Жылды таңдау (басы)',
    select_end_year: 'Жылды таңдау (соңы)',
    remove: 'Алып тастау',
    no_certif: 'Сертификат жоқ',
    from_year: 'Жылдан бастап',
    to_year: 'Жылға дейін',
    else: 'Тағы',
    planned: 'Жоспарланған',
    finished: 'Аяқталды',
    touched: 'Кейінге қалдырылды',
    cancelled: 'Тоқтатылды',
    else_lesson: 'Тағы сабақ',
    certif_req_add: 'Тіл сертификатының талаптарын қосыңыз',
    lang_req_add: 'Тіл талаптарын қосыңыз',
    req_add: 'Талаптар қосу',
    req_s: 'Талаптар',
    form_of_property: 'Меншік түрі',
    edit_del_property: 'Меншік түрін өңдеу/өшіру',
    add_property: 'Меншік түрін қосу',
    name_property: 'Меншік түрінің аты',
    toast_property_created_success: 'Меншік түрі сәтті қосылды!',
    toast_property_deleted_success: 'Меншік түрі сәтті өшірілді!',
    toast_property_upded_success: 'Меншік түрі сәтті өңделді!',
    optional: 'міндетті емес',
    all_list: 'Барлық бағдарламалар',
    discleim: 'Біздің жүйе сізге ең қолайлы опцияларды таңдауы үшін қажетті ақпаратты толтырыңыз. Жүйе бастапқы ақпаратты толтырғаннан кейін ғана әрі қарай өтуге мүмкіндік береді',
    fill_all: 'Барлығын толтырыңыз!',
    by_default: 'Әдепкі',
    start_expensive: 'Қымбаттан бастап',
    start_cheap: 'Арзаннан бастап',
    sort_by_cost: 'Оқу ақысы бойынша',
    sort_by_price: 'Тапсыру құны бойынша',
    check_mentor: 'Сенімді куратор',
    priority_consideration: 'Басымды үміткер болыңыз!',
    econom_benefit: 'Үнемдеу және жеңілдіктер',
    partnership_univ: 'Қосымша бонус!',
    welcome_platform: 'Біздің платформаға қош келдіңіз!',
    agragate_excourse_1: 'Біз жай ғана құжат жүктейтін портал емеспіз. Excourse – қазақша жоба, білім беру бағдарламаларының агрегаторы.',
    our_mission_1: 'Біздің міндетіміз – құжаттарды тапсыру процесін жеңілдету, тезірек және арзанырақ ету.',
    if_u_have_question: 'Біз бұл мәселелерде сарапшылармыз!',
    what_kind_doc_u_need: 'Қабылдау үшін қандай құжаттар қажет?',
    is_motiv_letter_right: 'Менің мотивациялық эссем дұрыс жазылған ба?',
    how_to_find_error: 'Құжаттардағы қателерді қалай анықтауға болады?',
    proceed_to_paym: 'Tөлемге өтіңіз',
    mentor_feedback_first_1: 'Әрбір құжатты тәлімгерлеріміз тексереді. Біз сізге барлығын мінсіз орындауға көмектесеміз және қабылдау мүмкіндігін арттыру үшін үнемі',
    mentor_feedback_first_2: 'кері байланыс береміз.',
    mentor_feedback_first_3: '',
    mentor_feedback_first_4: 'Кәсіби көзқарас',
    mentor_feedback_first_5: 'қателіктерден аулақ болуға және құжаттардың бәсекеге қабілетті пакетін жинауға көмектеседі.',
    priority_consider_1: 'Серіктестік университетті таңдаған кезде бірінші кезекте сіздің құжаттарыңыз қарастырылады. Сіздің',
    priority_consider_2: 'өтінішіңіз жылдамырақ өңделеді',
    priority_consider_3: 'және оқуға шақыру алу ықтималдығы бірнеше есе артады. Бұл жай ғана құжаттарды жіберу емес –',
    priority_consider_4: 'нәтижеге деген сенімділік.',
    econom_benefit_1: '50 000 теңгеге сіз',
    econom_benefit_2: 'оқуға түсу мүмкіндігіңізді айтарлықтай арттыру үшін',
    econom_benefit_3: 'кураторлардан құжаттар мен нұсқаулықтарды жан-жақты тексеру аласыз.',
    not_all_1: 'Excourse бонус ретінде',
    not_all_2: 'IELTS-ке дайындық материалдарын',
    not_all_3: 'ұсынады. Бұл қосымша артықшылық тіл дағдыларыңызды жақсартуға және тестке дайындалуға мүмкіндік береді.',
    edu_you_have_already: 'Біліміңізді көрсетіңіз (осы форманы толтыру кезінде)',
    fill_school_p: 'Егер сіз әлі мектепте болсаңыз, «МЕКТЕП ОҚУШЫСЫ» деп жазыңыз. Басқа жағдайларда өз мамандығыңызды көрсетіңіз',
    fill_gpa: 'егер сіз мектеп оқушысы болсаңыз немесе мектепті енді ғана бітірген болсаңыз, \'Бағалау парағы\' жолына - \'Баға\', ал \'Ұпай\' жолына мектепті қандай бағамен бітіргеніңізді жазыңыз. Университеттің/колледждің түлектері (студенттері) GPA баллыңызды көрсетіңіз.',
    fill_period: 'Оқуыңыздың басталу және аяқталу жылын көрсетіңіз.',
    five_scale: '5 балдық шкала (мектеп оқушылары үшін, баға)',
    four_scale: '4 балдық шкала (студенттер үшін, GPA)',
    hundred_scale: '100% шкала',
    swipe_up: 'жоғары сырғытыңыз',
    select_programm: 'Бағдарламаны таңдаңыз',
    not_free_teacher_time: 'Белгіленген уақытта бос мұғалім жоқ',
    utm_page: 'UTM белгілер',
    final_document: 'Қорытынды құжаттар',
    no_utm: 'UTM белгілері жоқ',
    note_order_only_integers: 'Кезек тек бүтін сандарды қабылдайды',
    enter_name: 'Атын енгізіңіз',
    download_doc: 'Құжат жүктеу',
    smthg_went_wrong: 'Бірдеңе дұрыс болмады! Қайта жүктеп көріңіз!',
    doc_uploaded: 'Құжат жүктелді!',
    doc_del: 'Құжат сәтті өшірілді!',
    pack: 'Пакет',
    country_edu: 'Оқитын мемлекеті',
    for_mailing: 'Тарату үшін',
    we_prepare_program: 'Күте тұрыңыз! Біз сіз үшін бағдарламаны таңдаудамыз',
    file_larger: 'Көлемі 20 МБ-тан аспайтын файл таңдаңыз!',
    stages: 'Кезеңдер',
    academic_year: 'Оқу жылы',
    responsible: 'Жауапты',
    user_status: 'Статус',
    package_cost: 'Пакет құны',
    balance_of_payment: 'Төлем қалдығы',
    ielts_preparation: 'IELTS дайындық',
    ielts_teacher: 'IELTS мұғалімі',
    ielts_available: 'IELTS бар',
    ielts_takes: 'IELTS тапсырады',
    visa: 'Виза',
    visa_support: 'Визалық қолдау',
    applying_by_proxy: 'Сенім бойынша қызмет етеміз',
    registered_for_visa: 'Визаға жазылды',
    visa_date: 'Виза күні',
    applied_for_visa: 'визаға өтініш берді',
    Visa_re_registration: 'визаны қайта ресімдеу',
    guardian: 'қамқоршы',
    visa_submission_city: 'визаға өтініш берген қала',
    visa_ready: 'виза дайын',
    legal_who_is_translating: 'легализациялауды кім аударады',
    legal_translation_ready: 'Легал аударма дайын',
    legal_sent: 'Легал жіберілді',
    legal_ready: 'легал дайын',
    tracking_number_Legal: 'Легал бақылау нөмірі',
    DDV_sent: 'ДДВ жіберілді',
    DDV_ready: 'ДДВ дайын',
    Tracking_number_DDV: 'ДДВ бақылау нөмірі',
    Submitted_for_apostille: 'Апостильге тапсырды',
    apostille_ready: 'апостиль дайын',
    codice_confirm: 'Кодиче растау',
    codice_ready: 'Кодиче жіберілді',
    questionnaire_for_finish_passport: 'Анкетирование на финку',
    list_of_financial_documents_ready_sent: 'тізім фин дайын/жіберілді',
    financial_documents_ready: 'фин құжат дайын',
    submitted_for_Finnish_passport_grant: 'Финкаға берді (грант)',
    access: 'Доступы',
    departure_date: 'Дата вылета',
    residence_permit_submitted: 'ВНЖ подали',
    CAF_submission: 'подача КАФ',
    SIM_opened: 'SIM открыли',
    codice_fiscali_submitted: 'Codice fiscali подали',
    apartment_options: 'Квартира нұсқалары',
    found_housing: 'Үй таптық',
    date_task_type: 'Күн қою',
    grouped_by: 'Топтастырылған',
    gender: 'Жыныс',
    pre_enrollment: 'Pre-enrollment',
    pre_enrollment_wait: 'Pre-enrollment күту',
    pre_enrollment_have: 'Pre-enrollment бар',
    add_room: 'Квартира қосу',
    add_access: 'Добавить доступ',
    room: 'Квартира',
    select_image: 'Сурет қою',
    login_password: 'Логин/құпиясөз',
    apartment: 'Пәтердің сипаттамасы',
    see_other_programs: 'Басқа бағдарламаларды қарау',
    need: 'Керек',
    not_need: 'Керек емес',
    yes: 'Иә',
    no: 'Жоқ',
    online: 'Онлайн',
    offline: 'Офлайн',
    apply_action_tofilter_or_current_page: 'Белсенді сүзгіге немесе тек таңдалған қолданушыларға негізделген барлық пайдаланушыларға әрекетті қолдану керек пе?',
    to_current_page: 'Таңдалған қолданушылар үшін',
    to_active_filter: 'Белсенді сүзгіге арналған',
    applies: 'Қолдану',
    your_mailing_send_to_user: 'Сіздің ақпаратыңыз пайдаланушыларға көп мөлшерде жіберіледі',
    per: 'адам',
    cv: 'CV',
    rec1: 'Рек-ое письмо №1',
    rec2: 'Рек-ое письмо №2',
    motiv_letter: 'Мот-ое письмо',
    ready: 'Дайын',
    not_ready: 'Дайын емес',
    wrote: 'Жазды',
    not_wrote: 'Жазбады',
    history_status: 'Статус тарихы',
    change_status: 'Статус өзгерту',
    change_status_success: 'Статус сәтті өзгертілді!',
    is_need_status: 'Статусқа бағдарлама керек',
    subscription_to_tgbot: 'Бот',
    date_of_payment: 'Төлем уақыты'
  },
  en: {
    course: 'Programs',
    school_course: 'Programs',
    my_course: 'My programs',
    schedule: 'Schedule',
    user: 'Users',
    analytics: 'Analytics',
    catalog: 'Catalog',
    onboard: 'Onboarding',
    info: 'Info',
    admin_school: 'Admin',
    manager: 'Manager',
    teacher: 'Teacher',
    student: 'Student',
    create_course: 'Create a program',
    filter: 'Filter',
    choose_category: 'Select a category',
    choose_price: 'Select a price',
    select_level: 'Select a level',
    select_certif: 'Select a certificate',
    search_by_text: 'Search by text',
    count_of_stud: 'Count of students',
    count_of_lessons: 'Count of lessons',
    students: 'students',
    lessons: 'lessons',
    course_name_kz: 'A program name in kazakh',
    course_name_ru: 'A program name in russian',
    course_name_en: 'A program name in english',
    lecturer_short: 'Teachers',
    lecturer: 'Teachers',
    lecture: 'Teacher',
    the_students: 'Students',
    the_studentss: 'Students ',
    lesson: 'Lessons',
    categories: 'Categories',
    category: 'Category',
    actions: 'Actions',
    delete: 'Delete',
    edit: 'Edit',
    view: 'View',
    view_more: 'See more details',
    my_profile: 'My profile',
    exit: 'Exit',
    back: 'Back',
    creating_course: 'Creating a program',
    add_category: 'Add a category',
    add: 'Add',
    del_category: 'Delete a category',
    del_edit_category: 'Edit/Delete a category',
    add_degree: 'Add a degree',
    del_degree: 'Delete a degree',
    del_edit_degree: 'Edit/Delete a degree',
    main_info: 'Main information',
    choose_lang_course: 'Select language of program',
    instruction_add_course: 'Video instruction for creating a program',
    course_not: 'No programs have been created.',
    try_add_course: 'It is time to create them!',
    price_of_course: 'Cost of course',
    price_of_study: 'Cost of education',
    price_of_study_currency: 'Tuition fees (in foreign currency)',
    free_course: 'Free program',
    category_of_course: 'Category of the course',
    display_the_course: 'Show the program in catalog',
    send_notif_course: 'Send mailings',
    add_unverified: 'Add for sorting "Unverified"',
    select_teacher: 'Select teacher',
    close: 'Close',
    search_by_teacher: 'Search by teacher...',
    search_by_student: 'Search by student...',
    selected_teachers: 'Selected teachers',
    selected_students: 'Selected students',
    details_of_course: 'Details of program',
    short_course_desc_ru: 'Short description of the program in russian',
    short_course_desc_kz: 'Short description of the program in kazakh',
    short_course_desc_en: 'Short description of the program in english',
    full_course_desc_ru: 'Full description of the program in russian',
    full_course_desc_kz: 'Full description of the program in kazakh',
    full_course_desc_en: 'Full description of the program in english',
    show_catalog_course: 'Appears in the program catalog',
    course_cover: 'Cover of the program',
    this_img_will_be_show_on_course_cover: 'This image will be displayed in the program catalog',
    path_to_file: 'Specify the path to the file or drag it to this area.',
    not_exceed: 'The file must not exceed 30 MB',
    not_exceed_20: 'The file must not exceed 20 MB',
    select_file: 'Select a file',
    media_on_page: 'Media on the program page (optional)',
    it_can_be_photo: 'It can be a photo, video, demo lesson or you can attach a link from Youtube',
    course_created_success: 'The program created successfully!',
    course_round_created_success: 'Rounds saved successfully!',
    course_poster_saved_success: 'Media saved successfully!',
    course_rounds_limit: 'Number of rounds is limited!',
    category_created_success: 'Category successfully created!',
    course_created_error: 'Error! Check the data!',
    auth: 'Authorization',
    auth_mail: 'Your email',
    auth_password: 'Your password',
    auth_forget_pass: 'Forgot your password?',
    recall_password: 'I remembered the password',
    auth_enter: 'Sign in',
    lang_course: 'Language of study',
    title_course: 'Search by text',
    cost_course: 'Price',
    recovery_password: 'Password recovery',
    recovery: 'Recovery',
    mail_on: 'The letter is in your mail!',
    confirm_reset_password: 'The generated password has been sent to your email!',
    go_back: 'Come back',
    fill_fields: 'Please, fill out this field',
    select_all: 'Select all',
    remove_all: 'Remove all',
    category_name: 'Category name',
    about_course: 'About the program',
    lesson_list: 'List of lessons',
    course_analytic: 'Course analytics',
    edit_course: 'Edit the program',
    edit_programs: 'Edit the programs',
    delete_course: 'Delete the course',
    delete_lesson: 'Delete the lesson',
    categories_of_course: 'Course categories',
    features_of_course: 'Advantages',
    sure_remove_entry: 'Are you sure you want to delete this entry?',
    sure_remove_lesson_schedule: 'Are you sure you want to remove this lesson schedule',
    sure_remove_work_schedule: 'Are you sure you want to remove this work schedule',
    sure_remove_course: 'Are you sure you want to remove this program',
    sure_delete_course: 'Are you sure you want to delete this program',
    sure_delete_selected_course: 'Are you sure you want to delete selected programs',
    sure_delete_lesson: 'Are you sure you want to delete this lesson',
    sure_delete_group: 'Are you sure you want to delete this group',
    sure_delete_user: 'Are you sure you want to delete this user',
    agree_del: 'Yes, delete',
    cancel: 'Cancel',
    all_lessons: 'All lessons',
    all_sections: 'All sections',
    create_lessons: 'Create a section',
    vido_lesson: 'Video lesson',
    test_lesson: 'Test',
    live_lesson: 'Live lesson',
    duration: 'Duration',
    minute: 'min',
    preview: 'Preview',
    no_lesson: 'There is no section.',
    add_teacher: 'Add teacher',
    add_student: 'Add student',
    groups: 'Groups',
    courses_teacher: 'Program teachers',
    courses_student: 'Program students',
    fullname: 'First name, Last name',
    mail: 'Email',
    phone: 'Phone',
    birth: 'Date of birth',
    courses_group: 'Program groups',
    add_group: 'Create a group',
    name_group: 'Group name',
    edit_group: 'Edit group',
    saturday: 'Saturday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    sunday: 'Sunday',
    select_instructor: 'Select teacher',
    save: 'Save',
    seacrh_teacher: 'Search by teachers',
    select_student: 'Select student',
    search_student: 'Search by students',
    delete_teacher: 'Remove teacher',
    delete_student: 'Remove student',
    delete_group: 'Remove group',
    sure_remove_teacher_from_course: 'Are you sure you want to remove the teacher from the program',
    agree_remove: 'Yes, remove',
    sure_remove_student_from_group: 'Are you sure you want to remove the student from the group',
    create_group: 'Create a group',
    instruction_add_group: 'Video instruction for creating a group',
    group_name: 'Group name',
    group_teacher: 'Group teacher',
    select_students: 'Select students',
    group_type: 'Group type',
    trial_lesson: 'Trial lesson',
    indiv_lesson: 'Individuallesson',
    group_lesson: 'Group lesson',
    pair_lesson: 'Paired lesson',
    groups_schedule: 'Group schedule',
    no_schedule: 'Currently no schedule',
    add_lesson: 'Add lesson',
    select_lesson_format: 'Select lesson format',
    day_of_week: 'Day of week',
    start_time: 'Start time',
    end_time: 'End time',
    amount_of_course: 'Amount of prog.',
    amount_of_lesson: 'Amount of lesson',
    last_login: 'Last entry (days)',
    add_user: 'Add user',
    role: 'Role',
    action: 'Actions',
    reset_filter: 'Reset filter',
    del_user: 'Delete user',
    video_instruction_create_user: 'Video instruction for creating a user',
    creating_user: 'Creating a user',
    select_role: 'Select role',
    create_user: 'Create user',
    name: 'Name',
    last_name: 'Last name',
    password: 'Password',
    generate: 'Generate',
    email_has_already: 'The mail already exists!',
    change_photo: 'Change photo',
    teachers_courses: 'Teachers courses',
    work_schedule: 'Work schedule',
    add_course: 'Add course',
    add_graph: 'Add graph',
    select_course: 'Select course',
    search_by_course: 'Search by courses',
    remove_course: 'Remove course',
    add_work_schedule: 'Add work schedule',
    add_lesson_schedule: 'Add lesson schedule',
    choose_suit_schedule: 'Select a suitable schedule',
    start: 'Start',
    end: 'End',
    remove_work_schedule: 'Remove work schedule',
    main: 'Main',
    communications: 'Communication',
    mailing: 'Mailimg',
    comment: 'Comments',
    documents: 'Documents',
    document: 'Document',
    filter_by_date: 'Filter by date:',
    from: 'from',
    to: 'to',
    your_comment: 'Your comment is here',
    add_comments: 'Add comment',
    balance: 'Student balance',
    students_course: 'Student courses',
    payment: 'Payment',
    lessonss: 'lesson(s)',
    add_pay: 'Add a payment',
    show_p_history: 'Show history',
    study_schedule: 'Study schedule',
    remove_lesson_schedule: 'Remove lesson schedule',
    lesson_format: 'Lesson format',
    lesson_type: 'Lesson type',
    select_format_lesson: 'Select lesson format',
    select_type_lesson: 'Select lesson type',
    select_schedule: 'Select schedule',
    add_schedule: 'Add schedule',
    search_payment: 'Search for payment by text',
    payment_history: 'Payment history',
    payment_date: 'Date of payment',
    recommend_teacher: 'Recommended teachers',
    video_instruction_create_lesson: 'Video instruction for creating a section',
    create_lesson: 'Create a section',
    info_about_lesson: 'Section Information',
    constructor: 'Task constructor',
    name_of_lesson: 'Section name',
    video_material: 'Video material',
    select_video_display_lesson: 'Select the video to be displayed in the section',
    lesson_section: 'Sections',
    section_name: 'Section name',
    add_section: 'Add section',
    order: 'Order',
    create: 'Create',
    task_test_type: 'Test task',
    task_media_test_type: 'Test task with image',
    task_media_type: 'Insert a media file',
    task_youtube_type: 'Insert a Youtube link',
    task_word_enter_type: 'Insert the words into the text',
    task_word_enter_select_type: 'Insert words with options',
    task_table_enter_type: 'Insert the word in the table',
    task_table_enter_select_type: 'Insert options in the table',
    task_find_error_type: 'Find errors in the text',
    task_text_type: 'Text task',
    task_compare_type: 'Compare the words',
    task_essay_type: 'Write an essay',
    task_file_check_type: 'Student file checking',
    task_file_add_type: 'Add the file to the lesson',
    task_select_one_type: 'Select one option',
    create_task_lesson: 'Create a task for the lesson',
    task_type: 'Task type',
    answer_options: 'Answer options',
    note_correct_answer: 'Also note the correct answers',
    immediately_show_correct_answer: 'Immediately show the correct answer to the student',
    option: 'Option',
    add_options: 'Add an option',
    save_task: 'Save a task',
    correct_answer: 'This is the right option',
    answer_text: 'The text of the question',
    add_file: 'Add file',
    would_be_ppt_media: 'It can be a presentation or a video lesson',
    recommend_file: 'File recommendations',
    img_format: 'Image formats',
    video_format: 'Video formats',
    video_audio_format: 'Video/audio formats',
    audio_format: 'Audio formats',
    file_format: 'File formats',
    if_media_more: 'If the file is larger than',
    mb: 'Мb',
    media_recom: 'Smaller images load and display faster on a computer display',
    usefull_links: 'Useful links:',
    ok: 'Fine',
    img_compression: 'Image compression',
    video_compression: 'Video compression',
    audio_compression: 'Audio compression',
    step_1_2: 'Step 1/2. Task text',
    step_2_2: 'Step 2/2. Highlight the words to be inserted',
    save_text: 'Save text',
    back_to_text: 'Return to text',
    text_your_text: 'Write your text here and then you can add words for gaps',
    add_pair: 'Create pairs',
    create_pair: 'Add a pair',
    field_essay: 'The student will have a field to enter an essay',
    field_load: 'The student will have a field to upload a file',
    pin: 'Pin',
    name_of_task: 'Task name',
    pin_student_file: 'You can attach files for students to download',
    step_1_2_table: 'Step 1/2. Make and complete the table',
    strings: 'Rows',
    columns: 'Columns',
    error_options: 'Error variant',
    add_task: 'Add task',
    mulitmedia: 'Multimedia',
    fill_all_gaps: 'You need to fill in all the gaps with the words that you think are correct',
    essay_text: 'Text',
    your_note: 'Your note',
    delete_task: 'Delete task',
    sure_delete_task: 'Are you sure you want to delete this task',
    word_1: 'Word 1',
    word_2: 'Word 2',
    cannot_change: 'You cannot change the number of columns and rows!',
    not_found_query: 'Sorry, no results were found for your search!',
    catalog_empty: 'The catalog is currently empty',
    user_empty: 'The user list is currently empty',
    edit_lesson: 'Editing the section',
    edit_lessons: 'Edit sections',
    my_balance: 'My balance',
    start_lesson: 'Start the lesson',
    start_course: 'Start the course',
    buy_course: 'Buy the course',
    course_of_lang: 'Language of course',
    del_section: 'Delete the section',
    edit_section: 'Edit the section',
    sure_delete_section: 'Are you sure you want to delete the section',
    exercises_empty: 'Currently there is no section for tasks',
    end_lesson: 'Finish the lesson',
    a_lesson: 'Lesson',
    answers: 'Answers',
    sure_finish_lesson: 'Are you sure you want to complete the lesson',
    agree_finish: 'Yes, finish',
    interactive_board: 'Interactive board',
    back_to_tasks: 'Back to tasks',
    edit_work_schedule: 'Edit work schedule',
    reset_answers: 'Reset answers',
    check: 'Check',
    a_course: 'Course',
    clear: 'Clear',
    doesnt_support: 'Your browser does not support this file',
    students_answer: 'Students answers',
    answer_no: 'Students have no answer',
    choose_group: 'Select group',
    empty_payment_history: 'No payment history',
    select_section: 'Select section',
    a_group: 'Group',
    an_action: 'Action',
    write: 'Write to',
    correct: 'correct',
    the_tasks: 'task(s)',
    show_answers: 'Show answers',
    hide_answers: 'Hide answers',
    correct_answers: 'Correct answers',
    a_correct_answer: 'The correct answer',
    students_answers: 'Student answers',
    a_students_answer: 'The answer of the student',
    send_letter: 'Send mail',
    send: 'Send',
    subject_mail: 'Mail subject',
    text_mail: 'Mail text',
    date: 'date',
    recommend: 'Recommended',
    regular_lesson: 'Regular lessons',
    trial_lessons: 'Trial lessons',
    empty_data_list: 'There is no data at the moment',
    empty_document_list: 'There are no documents at the moment',
    empty_comment_list: 'There are no comments at the moment',
    empty_schedule_list: 'There is no study schedule at the moment',
    edit_lesson_schedule: 'Edit study schedule',
    today: 'Today',
    week: 'week',
    day: 'day',
    my_settings: 'My settings',
    personal_settings: 'Personal settings',
    school_settings: 'School settings',
    newsletter: 'Mailing',
    my_tariff: 'My tariff',
    country: 'A country',
    school_name: 'School name',
    school_desc: 'School description',
    school_adress: 'School address',
    school_currency: 'School currency',
    school_allowed_cancel: 'Number of allowed cancellations per month',
    school_allowed_cancel_for: 'Cancellation allowed for',
    work_time: 'Working hours',
    auto_lesson: 'Automatically conduct lessons of yesterday',
    minute_f: 'minute',
    hour: 'hour',
    a_hour: 'hour',
    school_allowed_transfer: 'Number of allowed transfers per month',
    school_allowed_transfer_for: 'Transfer allowed for',
    hi_test: 'Hello, Test!',
    notif_lesson: 'We remind you that you have a lesson on the course {course name} through {lesson.time_from} with teacher {student name}',
    wish_lesson: 'We wish you to enjoy the lesson',
    if_cant_notif: 'If you do not succeed, you can reschedule or cancel the lesson in your personal account',
    with_scincerly: '– Best regards, {school name} team!',
    notification_lesson: 'Lesson reminder',
    new_mailing: 'New mailing',
    create_mailing: 'Create a mailing',
    recipients: 'Recipients',
    over_period_time: 'Over a period of time',
    all: 'All',
    text_mailing: 'Mailing text',
    trial: 'Trial',
    indiv: 'Individual',
    group: 'Group',
    pair: 'Doubles',
    change: 'Change',
    add_mailing: 'Add mailing',
    delete_notif: 'Delete reminder',
    sure_notif_delete: 'Are you sure you want to delete the reminder',
    update_tariff: 'Update tariff',
    payment_a_history: 'Payment history',
    active: 'Active',
    no_active: 'Not active',
    monthly_payment: 'Monthly payment',
    number_students: 'Number of students',
    next_payment: 'Next payment',
    number_months: 'Number of months',
    total: 'Total:',
    pay: 'Pay',
    sum: 'Sum',
    transactions: 'Transaction No.',
    description: 'Description',
    add_photo: 'Add photo',
    lesson_done: 'Lesson done',
    reschedule_lesson: 'Reschedule the lesson',
    cancel_lesson: 'Cancel lesson',
    return: 'Return',
    filter_by_schedule: 'Filter by schedule',
    by_lesson: 'By lessons',
    by_students: 'By students',
    empty_analytic: 'At the moment the analytics is empty.',
    empty_analytic_1: 'Start developing your school so that analytics appear',
    graphs: 'Graphs',
    report: 'Report',
    jan: 'JAN',
    feb: 'FEB',
    mar: 'MAR',
    apr: 'APR',
    may: 'MAY',
    jun: 'JUN',
    jul: 'JUL',
    avg: 'AVG',
    sen: 'SEN',
    okt: 'OKT',
    nov: 'NOV',
    dec: 'DEC',
    january: 'january',
    february: 'february',
    march: 'march',
    april: 'april',
    mays: 'may',
    june: 'june',
    july: 'july',
    august: 'august',
    september: 'september',
    october: 'october',
    november: 'november',
    december: 'december',
    select_by_course: 'Select by the program',
    select_by_status: 'Select by the status',
    amount_of_purchase: 'Number of purchases',
    sum_of_sales: 'Sales amount',
    search__student: 'Search a student',
    search__teacher: 'Search a teacher',
    more: 'More',
    purchases: 'Purchases',
    selling: 'Selling',
    done: 'Done',
    progress: 'Progress',
    current_month: 'Current month',
    conducted_lesson: 'Conducted lessons',
    canceled_lesson: 'Canceled lessons',
    transfered_lesson: 'Transferred lessons',
    statistic_type_lesson: 'Statistics by lesson type',
    individual_lesson: 'Individual lessons',
    conducted: 'Conducted',
    canceled: 'Canceled',
    postponed: 'Postponed',
    implementation: 'Implementation',
    select_by_student: 'Select by student',
    a_lessons: 'lesson(s)',
    attendance_of_lessons: 'Attendance of lessons',
    effectiveness_of_lessons: 'Effectiveness of lessons',
    total_lessons: 'Total lessons',
    exercises: 'Exercises',
    a_correct: 'Correct',
    incorrect: 'Wrong',
    correct_done: 'Correct / Done',
    all_right_reserved: 'All rights reserved',
    personal_data: 'Processing of personal data',
    confidentiality: 'Confidentiality',
    terms_of_use: 'Terms of use',
    online_payments_security: 'Online payment security',
    open: 'Open',
    for_questions: 'For questions',
    fill_category_name: 'Fill in the name of the category',
    fill_course_name: 'Fill in the name of the course',
    fill_category: 'Select a category!',
    fill_teacher: 'Select a teacher!',
    fill_short_desc: 'Fill out a short course description!',
    fill_full_desc: 'Fill out the full course description!',
    fill_poster: 'Select course cover!',
    course_update_media_upload: 'The course has been successfully updated! Please wait, the media file is loading!',
    courses_update: 'The programs has been successfully updated!',
    lessons_update: 'The lessons successfully updated!',
    media_uploaded: 'Media file uploaded successfully!',
    round_updated: 'Date updated successfully!',
    round_deleted: 'Date delete successfully!',
    fill_media: 'Select media file!',
    fill_lesson_name: 'Fill in the name of the lesson!',
    lesson_created_success: 'Lesson successfully created!',
    fill_duration: 'Fill in the duration!',
    fill_order: 'Fill in the order!',
    lesson_edited_success: 'The lesson has been successfully updated!',
    fill_name: 'Fill in the name!',
    fill_last_name: 'Fill in the last name!',
    fill_phone: 'Fill in the phone!',
    profile_data_update: 'User personal data updated!',
    avatar_apdate: 'Avatar updated!',
    fill_school_name: 'Fill in the name of the school!',
    fill_adress_name: 'Fill in the address of the school!',
    fill_allow_cancel: 'Specify the number of allowed cancellations per month!',
    fill_allow_transfer: 'Specify the number of allowed transfers per month!',
    success_school_name: 'School data has been updated!',
    fill_email: 'Fill in the e-mail!',
    fill_password: 'Fill in the password!',
    user_created_success: 'User successfully was created!',
    user_updated_success: 'User successfully was updated!',
    user_deleted_success: 'User successfully was deleted!',
    toast_work_schedule: 'Work schedule added!',
    toast_work_schedule_edit: 'Work schedule updated!',
    fill_course: 'Select course!',
    fill_price: 'Entry the cost!',
    fill_count_lesson: 'Entry the number of lessons!',
    toast_course_add: 'The course added!',
    toast_course_edit: 'User balance data updated!',
    toast_study_schedule: 'Schedule added!',
    toast_study_schedule_edit: 'Schedule updated!',
    toast_format: 'Select lesson format!',
    toast_type: 'Select lesson type!',
    toast_group_name: 'Fill in the name of the group!',
    toast_students: 'Select student!',
    toast_group_create: 'Group successfully created!',
    toast_group_update: 'Group successfully updated!',
    toast_mail_send: 'The letter successfully was sent!',
    toast_mail_send_error: 'Error! Check the email address of the user!',
    toast_section_name: 'Fill in the name of the section!',
    toast_section_order: 'Fill in the order of the section!',
    toast_section_add: 'Section added!',
    toast_section_name_edit: 'Section data changed!',
    toast_section_delete: 'Section is successfully deleted!',
    toast_task_added: 'Task is added successfully!',
    toast_test_task: 'Fill in the task name!',
    toast_test_task_answer: 'Fill in the text of the question!',
    toast_test_task_options: 'Fill in the options text!',
    toast_test_task_options_add: 'Add an option!',
    toast_test_task_options_correct: 'Select the correct option!',
    toast_task_table_r_c: 'Entry the number of columns and rows!',
    toast_test_task_text: 'Fill in the text of the task!',
    toast_task_connect_add: 'Add a pair!',
    toast_task_connect_word: 'Fill in the words!',
    task_updated_success: 'The task successfully updated!',
    something_went_wrong: 'Something went wrong, please try again...',
    toast_notif_add: 'Mailing list successfully added!',
    toast_notif_delete: 'Mailing list is successfully deleted!',
    toast_comment_added: 'Comment is added successfully!',
    toast_comment_field: 'Fill in the comment field!',
    toast_avail_course_delete: 'The course is successfully deleted!',
    toast_avail_course_added: 'The course is successfully added!',
    toast_lesson_schedule_delete: 'The study schedule is successfully deleted!',
    empty_notif_list: 'There are no notifications at the moment',
    toast_work_schedule_delete: 'The work schedule is successfully deleted!',
    toast_lesson_delete: 'The lesson is successfully deleted!',
    toast_lesson_copy: 'The lesson is successfully copied!',
    toast_teacher_course_remove: 'The teacher is successfully removed from the list!',
    toast_teacher_course_added: 'The teacher is successfully added to the list!',
    toast_student_course_remove: 'The student is successfully removed from the list!',
    toast_student_course_added: 'The student is successfully added to the list!',
    empty_course: 'No course at the moment.',
    my_group: 'My groups',
    my_groups_student: 'Students in my group',
    search: 'Search',
    no_matches: 'No matches',
    kazakh: 'Qazaq',
    rus: 'Russian',
    eng: 'English',
    turk: 'Turkish',
    nem: 'German',
    venger: 'Hungarian',
    grant: 'Grant',
    toast_del_category_success: 'Category deleted successfully!',
    toast_edit_category_success: 'Category updated successfully!',
    copy: 'Copy the course',
    copy_lesson: 'Copy the lesson',
    toast_copy_success: 'Course copied successfully!',
    toast_empty_program: 'There is no section in the program!',
    degree_name: 'A degree',
    toast_degree_created_success: 'Degree successfully created!',
    toast_degree_deleted_success: 'Degree successfully deleted!',
    toast_degree_upded_success: 'Degree successfully edited!',
    select_degree: 'Select a degree',
    select_format: 'Select format',
    select_range_data: 'Select date range',
    deadlines_for_submission: 'Deadlines for submission',
    offer: 'Offer',
    i_have_acc: 'I have an account',
    i_have_no_acc: 'I don\'t have an account',
    account_create: 'Account created',
    registrate: 'Sign up',
    italy: 'Italian',
    greece: 'Greek',
    chez: 'Czech',
    terms: 'Terms',
    all_user: 'All users',
    del_format: 'Delete format',
    del_edit_format: 'Edit/Delete format',
    add_format: 'Add format',
    fill_format: 'Fill in the name of the format',
    name_format: 'Format name',
    course_format: 'Format',
    toast_format_created_success: 'Format created successfully!',
    toast_format_deleted_success: 'Format deleted successfully!',
    toast_format_upded_success: 'Format updated successfully!',
    format: 'Format',
    univer: 'University',
    del_univer: 'Delete university',
    del_edit_univer: 'Edit/Delete university',
    add_univer: 'Add university',
    fill_univer: 'Fill in the name of the university',
    name_univer: 'University name',
    toast_univer_created_success: 'University created successfully!',
    toast_univer_deleted_success: 'University deleted successfully!',
    toast_univer_upded_success: 'University updated successfully!',
    speciality: 'Faculty',
    del_special: 'Delete faculty',
    del_edit_special: 'Edit/Delete faculty',
    add_special: 'Add faculty',
    fill_special: 'Fill in the name of the faculty',
    name_special: 'Faculty name',
    toast_special_created_success: 'Faculty created successfully!',
    toast_special_deleted_success: 'Faculty deleted successfully!',
    toast_special_upded_success: 'Faculty updated successfully!',
    del_city: 'Delete city',
    del_edit_city: 'Edit/Delete city',
    add_city: 'Add city',
    fill_city: 'Fill in the name of the city',
    name_city: 'City name',
    course_city: 'City',
    toast_city_created_success: 'City created successfully!',
    toast_city_deleted_success: 'City deleted successfully!',
    toast_city_upded_success: 'City updated successfully!',
    city: 'City',
    poster_url: 'Link to video in Youtube',
    link_youtube: 'Attach video link from Youtube',
    submission_fee: 'Submission fee',
    submission_fee_tenge: 'Submission fee (tenge)',
    open_rounds: 'Feed is open',
    poster_media: 'Choose your media file',
    all_exercise_should_be_done: 'Complete all tasks',
    extended_filter: 'Extended filter',
    hide: 'Hide',
    how_it_works: 'How Excourse works?',
    text_onboard: 'Take a little training and use the platform easily and simply!',
    later: 'Later',
    get_trained: 'Get trained',
    select_training_section: 'Select a section of training',
    display_course_catalog: 'The catalog will display all available programs',
    educate: 'Education',
    educate_certfifcate: 'Education (сertificates)',
    certificate: 'Certificates',
    add_edu: 'Add an education',
    middle_school: 'Middle education (school, college)',
    school: 'School',
    college: 'College',
    bachelor: 'Bachelor degree',
    magistracy: 'Master degree',
    phd: 'PhD',
    profession: 'Profession',
    profession_name: 'Profession name',
    period_of_study: 'Period of study (a year)',
    ends: 'End',
    avg_rate: 'Average grade (grades, GPA and more)',
    form_of_rate: 'Form of assessment (GPA, score, or other)',
    rate: 'Grade',
    fill_profession_name: 'Fill in the name of the profession!',
    fill_start_period: 'Fill in the beginning of the study period!',
    fill_form_of_rate: 'Fill in the assessment form!',
    del_edu: 'Delete the education degree',
    sure_del_edu: 'Are you sure you want to remove this degree of education',
    toast_edu_add_success: 'Education degree added successfully!',
    toast_edu_edit_success: 'Education degree updated successfully!',
    toast_edu_delete_success: 'Education degree deleted successfully!',
    fill_certif_name: 'Fill in the name of the certificate!',
    add_certif: 'Add a certificate',
    a_certificate: 'Certificate',
    certif_name: 'Name (IELTS, TOEFL)',
    total_score: 'Total',
    toast_certif_add_success: 'The certificate added successfully!',
    toast_certif_edit_success: 'The certificate updated successfully!',
    toast_certif_delete_success: 'The certificate deleted successfully!',
    del_certif: 'Delete the certificate',
    sure_del_certif: 'Are you sure you want to delete this certificate',
    if_check_all_task_done: 'Checking that all tasks in a section have been completed',
    second_hint: 'Here are your personal settings',
    first_hint: 'You can see purchased programs in my programs panel',
    third_hint: 'Here are your schedule',
    fourth_hint: 'Analytics for your programs will appear here',
    select_learn_select: 'Select a training section',
    one_section: 'What sections are there in Excourse?',
    onboard_button: 'Completely watched the video',
    three_section: 'How and where to fill out and attach data/documents?',
    two_section: 'Where do I set up my profile?',
    press_my_profile: 'Click here and select "My Profile"',
    super: 'Super!',
    onboard_done: 'You have been trained on the platform!',
    next: 'Next',
    fill_edu_and_certif: 'Let us fill in the data on education and certificates in the sections "Education" and "Certificates"!',
    requisites: 'Requisites',
    thank_you: 'Thank you for your purchase!',
    numb_pay: 'Order number',
    country_category: 'Country/Category',
    search_pr_city_univ: 'Search (specialty, city, university)',
    free: 'Free',
    open_access: 'Open access',
    starts: 'Start',
    enter_a_code: 'Enter a code',
    sms_first: 'A code has been sent to the e-mail',
    sms_second: '. Enter it here:',
    sms_third: 'and to e-mail',
    catalog_of_scecial: 'Catalog of specialties',
    send_again: 'Send code again',
    send_again_2: 'after',
    number_phone_8: 'Phone number must start with "+7"',
    number_input_full: 'Fill in the phone number',
    send_code: 'Send code',
    faq: 'FAQ',
    faq_menu: 'FAQ',
    select_lesson: 'Select a lesson',
    select_es_list: 'Select task page',
    empty_exercise_list: 'There are no tasks at the moment',
    date_joined: 'Registration date',
    should_add_timer: 'Section with timer',
    timer: 'Timer',
    timer_stop: 'Time is up!',
    start_exam: 'Start an exam',
    finish_exam: 'Finish an exam',
    u_r_going_to_start: 'You are starting tasks',
    result: 'Result',
    time: 'Time',
    video_warn: 'We apologize for the temporary absence of the video tutorial! All users, without exception, will have access to the video lesson after downloading it!',
    notes: 'The note',
    checked: 'Checked',
    tgram: 'Telegram',
    toast_hw_sent_success: 'Task responses sent successfully!',
    all_checked: 'All checked',
    no_checked: 'Not checked',
    tasks: 'Tasks',
    round_should_be_today_or: 'Note: Round date "by" must not be a past date',
    add_date: 'Add date',
    multi_media: 'Media file',
    from_comp: 'Upload media from computer',
    should_select_file: 'Please select a file!',
    selected_media: 'Selected files',
    count_of_massive_course: 'Reached quantity limit!',
    count_of_massive_course_select_one: 'Select programs one by one!',
    count_of_massive_section_select_one: 'Select sections one by one!',
    not_checked: 'Unverified',
    by_programms: 'By programs',
    by_education: 'By education',
    by_status: 'By status',
    edit_date: 'Filter by edit date:',
    edit_date_table: 'Edit date',
    min_percent: 'Set the minimum threshold percentage',
    not_task_with_grade_count: 'There are no tasks that calculate correct answers',
    dont_pass: 'You need to score a pass. The percentage should be higher than:',
    ielts: 'IELTS',
    toefl: 'TOEFL',
    fce: 'FCE',
    cae: 'CAE',
    cpe: 'CPE',
    bec: 'BEC',
    my_language_know: 'Level of language skills',
    my_language_know_add: 'Add foreign language',
    my_language_know_lang_input: 'A language',
    my_language_know_lang_input_select: 'Select the language',
    my_language_know_lang_input_level: 'A level',
    fill_lang_level_name: 'Select the language!',
    fill_lang_level: 'Select the language level of knowledge!',
    my_language_added_success: 'The information is saved successfully!',
    my_language_del_success: 'The information is delete successfully!',
    list_of_edu: 'The information about education',
    next_btn: 'Next',
    teaching: 'Education',
    language_edu: 'Language knowledge',
    select_start_year: 'Select year (start)',
    select_end_year: 'Select year (end)',
    select_city: 'Select city',
    select_univ: 'Select university!',
    remove: 'Remove',
    no_certif: 'No certificate',
    from_year: 'From year',
    to_year: 'By end of year',
    else: 'More',
    planned: 'Planned',
    finished: 'Finished',
    touched: 'Rescheduled',
    cancelled: 'Canceled',
    else_lesson: 'More lessons',
    certif_req_add: 'Add language certificate requirements',
    lang_req_add: 'Add language requirements',
    req_add: 'Add requirements',
    req_s: 'Requirements',
    form_of_property: 'Type of ownership',
    edit_del_property: 'Edit/Delete type of ownership',
    add_property: 'Add type of ownership',
    name_property: 'Name of type of ownership',
    toast_property_created_success: 'Type of ownership successfully added!',
    toast_property_deleted_success: 'Type of ownership successfully deleted!',
    toast_property_upded_success: 'Type of ownership successfully updated!',
    optional: 'optional',
    all_list: 'All programs',
    discleim: 'In order for our system to select the most suitable options for you, fill in the required information. The system will only allow you to proceed further after filling out the initial information',
    fill_all: 'Fill in all fields!',
    by_default: 'By deafult',
    start_expensive: 'Expensive first',
    start_cheap: 'Cheap first',
    sort_by_cost: 'By cost of education ',
    sort_by_price: 'By cost of submission',
    check_mentor: 'Trusted curator',
    priority_consideration: 'Become a priority applicant!',
    econom_benefit: 'Savings and benefits',
    partnership_univ: 'Additional bonus!',
    welcome_platform: 'Welcome to our platform!',
    agragate_excourse_1: 'We are not just a portal for uploading documents. Excourse is a Kazakhstani project, an aggregator of educational programs.',
    our_mission_1: 'Our mission is to make the process of submitting documents easier, faster and cheaper.',
    if_u_have_question: 'We are experts in these matters!',
    what_kind_doc_u_need: 'What documents are needed for admission?',
    is_motiv_letter_right: 'Is my motivation essay written correctly?',
    how_to_find_error: 'How to identify errors in documents?',
    proceed_to_paym: 'Go to payment',
    mentor_feedback_first_1: 'Each document will be checked by our mentors. We help to make everything perfect, and constantly',
    mentor_feedback_first_2: 'provide feedback',
    mentor_feedback_first_3: 'to increase your chances of admission.',
    mentor_feedback_first_4: 'A professional opinion',
    mentor_feedback_first_5: 'will help you avoid mistakes and put together a competitive package of documents.',
    priority_consider_1: 'When choosing a partner university, your documents will be considered first. Your',
    priority_consider_2: 'application will be processed faster',
    priority_consider_3: 'and the probability of receiving an invitation to study increases many times. This is not just sending documents - this is',
    priority_consider_4: 'confidence in the result.',
    econom_benefit_1: 'For 50,000 tenge you get a comprehensive document check from curators and guidelines',
    econom_benefit_2: 'to significantly increase your chances of admission.',
    econom_benefit_3: '',
    not_all_1: 'Excourse provides',
    not_all_2: 'IELTS preparation materials',
    not_all_3: 'as a bonus. This additional benefit will allow you to improve your language skills and prepare for the test.',
    edu_you_have_already: 'Specify your education (at the moment of filling out this form)',
    fill_school_p: 'If you are still in school, write "STUDENT SCHOOL". In other cases, specify the specialty',
    fill_gpa: 'If you are a school student or have just finished school, write in the \'Assessment Form\' field - \'Assessment\', and in the \'Score\' field the grade you finished (are finishing) school with. University/college graduates (students), indicate your GPA.',
    fill_period: 'Please indicate the year your studies began and ended.',
    five_scale: '5-point scale (for schoolchildren, grade)',
    four_scale: '4-point scale (for students, GPA)',
    hundred_scale: '100% scale',
    swipe_up: 'swipe up',
    select_programm: 'Select a programm',
    not_free_teacher_time: 'There is no free teacher for the given time',
    utm_page: 'UTM tags',
    final_document: 'Final documents',
    no_utm: 'No UTM marks',
    note_order_only_integers: 'The order only accepts integers',
    enter_name: 'Enter the name',
    download_doc: 'Upload document',
    smthg_went_wrong: 'Something went wrong! Try uploading again!',
    doc_uploaded: 'The document has been loaded!',
    doc_del: 'The document has been successfully deleted!',
    pack: 'Tariff',
    country_edu: 'Country of study',
    for_mailing: 'For mailing',
    we_prepare_program: 'Please wait! We are selecting a program for you',
    file_larger: 'Please select a file that is less than 20 MB in size!',
    stages: 'Stages',
    academic_year: 'Academic year',
    responsible: 'Responsible',
    user_status: 'Status',
    package_cost: 'Package cost',
    balance_of_payment: 'Balance of payment',
    ielts_preparation: 'IELTS preparation',
    ielts_teacher: 'IELTS teacher',
    ielts_available: 'IELTS available',
    ielts_takes: 'IELTS takes',
    visa: 'Visa',
    visa_support: 'Visa support',
    applying_by_proxy: 'Applying by proxy',
    registered_for_visa: 'Registered for visa',
    visa_date: 'Visa date',
    applied_for_visa: 'Applied for visa',
    Visa_re_registration: 'Re-registered for visa',
    guardian: 'Guardian',
    visa_submission_city: 'Visa submission city',
    visa_ready: 'Visa ready',
    legal_who_is_translating: 'Legalization who translates',
    legal_translation_ready: 'Legalization translation ready',
    legal_sent: 'Legal sent',
    legal_ready: 'Legal ready',
    tracking_number_Legal: 'Tracking number Legal',
    DDV_sent: 'DDV sent',
    DDV_ready: 'DDV ready',
    Tracking_number_DDV: 'Tracking number DDV',
    Submitted_for_apostille: 'Submitted for apostille',
    apostille_ready: 'Apostille ready',
    codice_confirm: 'Codice upload',
    codice_ready: 'Codice sent',
    questionnaire_for_finish_passport: 'Questionnaire for Finnish passport',
    list_of_financial_documents_ready_sent: 'list of financial documents ready/sent',
    financial_documents_ready: 'financial documents ready',
    submitted_for_Finnish_passport_grant: 'submitted for Finnish passport (grant)',
    access: 'Access',
    departure_date: 'Departure date',
    residence_permit_submitted: 'Residence permit submitted',
    CAF_submission: 'CAF submission',
    SIM_opened: 'SIM opened',
    codice_fiscali_submitted: 'Codice fiscali submitted',
    apartment_options: 'apartment options',
    found_housing: 'found housing',
    date_task_type: 'Insert date',
    grouped_by: 'Grouped by',
    gender: 'Gender',
    pre_enrollment: 'Pre-enrollment',
    pre_enrollment_wait: 'Pre-enrollment wait',
    pre_enrollment_have: 'Pre-enrollment has',
    add_room: 'Add appartment',
    add_access: 'Add access',
    room: 'An appartment',
    select_image: 'Insert image',
    login_password: 'Login/password',
    apartment: 'Description of the apartment',
    see_other_programs: 'View other programs',
    need: 'Need',
    not_need: 'Not need',
    yes: 'Yes',
    no: 'No',
    online: 'Online',
    offline: 'Offline',
    apply_action_tofilter_or_current_page: 'Apply action to all users for active filter or only for selected user?',
    to_current_page: 'For the selected user',
    to_active_filter: 'For the active filter',
    applies: 'Apply',
    your_mailing_send_to_user: 'Your mailing will be sent to users in the amount of',
    per: 'per.',
    cv: 'CV',
    rec1: 'Recom. letter №1',
    rec2: 'Recom. letter №2',
    motiv_letter: 'Mitiv. letter',
    ready: 'Ready',
    not_ready: 'Not ready',
    wrote: 'Wrote',
    not_wrote: 'Did not write',
    history_status: 'History of status',
    change_status: 'Change status',
    change_status_success: 'Status changed successfully!',
    is_need_status: 'Need status for program',
    subscription_to_tgbot: 'Bot',
    date_of_payment: 'Date of payment'
  }
}
